
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.set-admin-password {
  &__description {
    text-align: center;
  }
  &__form {
    margin-top: 40px;
    .static-alert {
      margin-bottom: 40px;
    }
  }
  &__input-wrapper {
    position: relative
  }
  &__paragraph-link {
    @include paragraph;
    color: $blue500;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}