
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  border: unset;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;
  font-weight: 600;
  min-width: 200px;
  &--large {
    min-height: 48px;
  }  
  &--medium {
    min-height: 40px;
  } 
  &--small {
    min-height: 32px;
    font-size: 12px;
  }
  &:hover {
    transition: 0.3s;
  }
  svg {
    margin-left: 5px;
  }
  &--disabled {
    cursor: not-allowed;
  }
}

.btn-secondary {
  background-color: $red500;
  color: $white;
  &:hover {
    background-color: $red600;
  }
}

.btn-primary {
  background-color: $blue500;
  color: $white;
  &:hover {
    background-color: $blue600;
  }
}

.btn-stroke {
  background-color: transparent;
  border: 2px solid;
  &--primary {
    border-color: $blue500;
    color: $blue500;
    &:hover {
      background-color: $blue500;
      color: $white;
    }
  }
  &--secondary {
    border-color: $red500;
    color: $red500;
    &:hover {
      background-color: $red500;
      color: $white;
    }
  }
}
.btn-plus {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
}