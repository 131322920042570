
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.door-sills {
  &__heading {
    display: flex;
    align-items: center;
    .btn {
      margin-left: auto;
      max-width: 300px;
      margin-top: unset;
    }
  }
  &__add-button {
    margin-top: 25px !important;
    max-width: 300px;
    margin-left: auto;
    margin-bottom: 35px;
  }
  .am-separator {
    margin: 35px 0 35px 0;
  }
}
.skirtingBoards .door-sills__add-button{
  margin-bottom: 30px;
}