
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.floor-area {
  

  &__equation {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 0;
    span {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      display: block;
      margin-top: -3px;
      margin: 0 25px;
      svg path{
        stroke: $graphite300;
      }
      // &:first-child {
      //   display: none;
      // }
    }
    .input__wrapper {
      margin: 0;
    }
  }
  &__equation-item {
    position: relative;
    width: 100%;
    max-width: calc(100% / 3);
    display: flex;
    align-items: center;
    &:first-child {
      width: calc(100% - 38px);
      span {
        display: none;
      }
      .input__wrapper {
        width: 100%;
      }
    }
    .input__wrapper {
      margin-bottom: 0 !important;
    }
    .input__error {
      display: none;
    }
  }
  &__equation-item-delete {
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: 2px;
    width: 14px;
    height: 14px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &__element {
    display: flex;
    align-items: center;
  }
  &__ended {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-top: 40px;
    gap: 20px;
    .input__wrapper {
      width: 100%;
    }
  }
}