
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.working-time  {
  display: flex;
  flex-direction: column;
}

.working-time-item {
  padding: 12px 20px;
  border: 1px solid $gray600;
  background-color: $gray300;
  border-radius: 6px;
  &__content {
    display: flex;
  }
  &--active {
    .working-time-bar__time, .single-timing__date, .working-time-bar__user {
      color: $graphite200 !important;
    }
    .single-timing {
      &--user {
        background-color: $gray400;
      }
      &--first {
        .input__content {
          visibility: visible !important;
        }
      }
    }
    .working-time-bar {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $gray300, $alpha: 0.3)
      }
    }
    .context-menu__trigger  svg circle{
     fill: $gray600;
    }
  }
  &:nth-child(n + 1) {
    margin-top: -1px;
  }
  &__left, &__right {
    width: 50%;
    display: flex;
  }
  &__left {
    align-items: center;
  }
  &__right {
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  &__name {
    color: $graphite400;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px; 
    min-width: 110px;
    display: flex;
    align-items: center;
  }
  &__start {
    color: $graphite200;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.12px; 
    display: none;
    @include tablet {
      display: block;
    }
  }
  &__timing {
    color: $graphite300;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.12px; 
    min-width: 50px;
    text-align: right;
  }
  &__timing-with-clock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $graphite200;
    svg {
      margin-right: 5px;
    }
  }
  &__buttons {
    display: flex;
    gap: 5px;
  }
  &__button {
    background-color: $gray500;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  &__menu{ 
    .context-menu__trigger {
      display: flex;
      align-items: center;
      height: 31px;
      margin-left: 20px;
    }
    svg circle{
      fill: $gray700;
    }
  }
}


.working-time-form {
  display: flex;
  gap: 14px;
  .select {
    &__wrapper {
      margin-left: -10px;
    }
    &__label {
      display: none;
    }
    &__control {
      background-color: $white !important;
      border: 1px solid $white !important;
      min-height: 29px !important;
      max-height: 29px !important;
      width: 120px;
    }
    &__value-container {
      padding: 0 6px !important;
    }
    &__indicator {
      padding: 0 8px;
      svg {
        width: 20px;
        height: 20px;
      }
      svg path {
        stroke: $graphite300;
      }
    }
    &__single-value {
      color: #4A5051;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.14px;
    }
    &__option {
      color: $graphite400;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px; 
      background-color: $white;
      &--is-selected {
        background-color: $gray400;
      }
    }
    &__menu {
      width: 166px;
    }
  }
  .input {
    background-color: unset;
    border: 1px solid transparent;
    min-width: 100px;
    padding: 0 4px;
    min-height: 31px;
    max-height: 31px;
    font-size: 12px !important;
    transition: 0.3s;
    @include tablet {
      min-width: 170px;
    }
    &__wrapper {
      margin-left: -5px;
    }
    &__error {
      display: none;
    }
  }
}


.working-time-bar {
  padding: 5px 10px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  &__user {
    color: $graphite400;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: -0.1px; 
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  &__time {
    color: $blue800;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.12px;
    margin-left: 10px;
    min-width: 40px;
    @include tablet {
      min-width: 60px;
    }
  }
  &__clock {
    margin-left: auto;
  }
}

.single-timing {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    &--comment {
      .input__content {
        visibility: visible !important;
      }
    }
    &--first {
      margin-top: 33px;
      @include tablet {
        .single-timing__date:after {
            content: 'Rozpoczęto';
            position: absolute;
            margin-top: -25px;
            color: $graphite200;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.12px; 
            left: 0;
          }
        .input__wrapper:after {
            content: 'Komentarz';
            position: absolute;
            margin-top: -25px;
            margin-left: 5px;
            color: $graphite200;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.12px; 
          }
      }
    }
    &--user {
      background-color: $gray500;
    }
    &--menu {
      margin-left: auto;
    }
    &__date {
      color: $graphite300;
      text-align: right;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.12px; 
      position: relative;
      flex-shrink: 0;
      margin-right: 5px;
    }
    &__form {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-around;
    }
    .input__wrapper {
      margin-left: unset;
      position: relative;
    }
    .input__content {
      visibility: hidden;
      transition: 0.3s;
    }
    &:hover {
      .input__content {
        visibility: visible;
      }
      input {
        background-color: $gray400; 
      }
    }
}


.single-timing-form {
  width: 100%;
  .input__wrapper {
    width: 100%;
    margin-right: 20px;
  }
}