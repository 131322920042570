
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.user-name-and-badge {

  &__user {
    display: flex;
    .user-badge {
      max-height: 25px;
      line-height: 16px;
      width: auto;
      border-radius: 100px;
      padding: 2px 15px;
      line-height: 21px;
      .user-badge__rest-of-title {
        display: inline !important;
        line-height: 21px;
      }
    }
  }
  &__user-item {
    display: flex;
    align-items: center;
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-right: 25px;
  }
}