
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.add-orders {
  .input__wrapper, .select__wrapper {
    margin-bottom: 24px; 
  }
  .modal__separator {
    margin-top: 16px;
  }
  .type-separator {
    margin: 20px 0;
  }
  
  .switch {
    &__wrapper  {
      flex-direction: column;
    }
    &__name {
      color: #333738;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 8px;
    }
    &__label {
      width: 50%;
      padding: 9px 20px;
    }
  }
  &__new-type {
    width: 100%;
    min-width: 100%;
    margin-top: 25px;
  }
  &__add-file {
    min-width: 147px;
  }
  &__measurements {
    display: flex;
    flex-direction: column;
    position: relative;

    &--remove {
      color: $graphite300;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      .svg {
        width: 20px;
        height: 20px;
      }
    }
    
  }
  &__photos {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }
  &__photos-button {
    min-width: 150px;
    transition: 0.3s;
    .svg path {
      stroke: $blue500;
    transition: 0.3s;

    }
    &:hover {
      transition: 0.3s;
      .svg path {
        stroke: #fff;
    transition: 0.3s;

      }
    }
  }
  &__photos-label {
    color: $blue500;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  &__photos-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 25px;
  }
  &__photos-item {
    display: flex;
    justify-content: space-between;
    background-color: $gray100;
    padding: 10px 15px;
    align-items: center;
    .svg {
      width: 20px;
      height: 20px;
    }
    span {
      color: $gray700;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
    div {
      cursor: pointer;
    }
  }
}
.am-separator--measurements-type {
  margin: 25px 0!important;
}