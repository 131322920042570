
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.aap {
  &__buttons {
    margin-top: 40px;
  }
  &__address-separator {
    margin-top: 20px;
  }
}