
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        

.etbd {
  width: 100%;
  @include tablet {
    min-width: 603px;
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    .btn {
      max-width: 241px;
    }
  }
  &__list {
    margin: 30px 0 50px 0;
    &--job {
      width: 50%;
      min-width: 193px;
    }
    &--task {
      width: 30%;
      min-width: 86px;
    }
  
    &--time {
      width: 20%;
      min-width: 85px;
    }
  }
  &__list-heading {
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid $gray500;
    margin-bottom: 20px;
    span {
      display: block;
      color: $graphite500;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
    }
  }
  &__item {
    padding: 10px 20px;
    display: flex;
    border-radius: 6px;
    background: $gray300;
    margin-bottom: 5px;
    &--job {
      width: 50%;
      min-width: 193px;
      color: $blue500;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.12px;
      display: flex;
      gap: 10px;
    }
    &--task {
      width: 30%;
      min-width: 86px;
      color: $graphite500;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
    }
  
    &--time {
      width: 20%;
      min-width: 85px;
      color: #042237;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.14px;
    }
  }
}