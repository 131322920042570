
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.mounting-accessories {
  margin: 35px 0;
  .checkbox-part__wrapper {
    width: 100%;
  }
}