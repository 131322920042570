
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.admin-password-link-expired {
  &__description {
    text-align: center;
    max-width: 325px;
    margin: 0 auto;
    @include tablet {
      max-width: 325px;
    }
    @include desktop {
      max-width: 100%;
    }
  }
  &__form {
    margin-top: 40px;
    .static-alert {
      margin-bottom: 40px;
    }
    .input__wrapper {
      margin-bottom: 30px;
    }
  }
  &__input-wrapper {
    position: relative
  }
  &__paragraph-link {
    @include paragraph;
    color: $blue500;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  &__bold {
    margin: 0 auto;
    margin-top: 20px;
  }
  &--link{ 
    margin-top: 50px;
    margin-bottom: -10px;
  }
}