
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.addition-side-window {
  margin-top: 30px;
  .am-separator {
    margin-top: 0;
  }
  .static-alert--show {
    margin-bottom: 35px;
  }
  .textarea__wrapper {
    margin-top: -20px;
  }
}

.error-dimension {
  display: flex;
  padding: 14px 24px;
  background-color: #FFDCC8;
  margin-bottom: 35px;
  border-radius: 6px;
  align-items: center;
  gap: 20px;
  @media(max-width: 1024px) {
    flex-direction: column;
  }
  p {
    max-width: 350px;
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    @media(max-width: 1024px) {
     text-align: center;
    }
  }
  .svg {
    flex-shrink: 0;
    margin-left: auto;
    @media(max-width: 1024px) {
      margin: 0 auto;
     }
  }
  .btn {
    margin: auto 0;
    max-width: 142px;
    min-width: 132px;
  }
}