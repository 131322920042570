
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.multiple-select {
  .close {
    pointer-events: all;
    cursor: pointer;
  }
  &__label {
    @include label;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__only-first {
    .multiple-select--large__multi-value, .multiple-select--medium__multi-value, .multiple-select--small__multi-value {
      background-color: transparent !important;
      padding-left: 0 !important;
      &:nth-child(n + 2){
        display: none;
      }
      &__remove {
        display: none;
      }
    }
    .multiple-select__value-container {
      padding-left: 0 !important;
    }
  }
  &__item {
    label {
      display: flex;
      align-items: center;
    }
  }
  &__item-color {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
  &--large, &--medium, &--small {
    &__clear-indicator {
      display: none !important;
    }
    &--disabled {
      pointer-events: none !important;
      .select__control {
      background-color: $gray300 !important;
      border: 2px solid $gray300 !important;
      }
    }
    &__value-container {
      padding: 0 !important;
      overflow: unset !important;
      &--is-multi {
        flex-wrap: nowrap !important;
      }
    }
    &__error {
      color: $error;
      line-height: 16px;
      font-family: "Open Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      // margin-top: 6px;
    }
    &__indicator-separator {
      display: none !important;
    }
    &__placeholder {
      color: $graphite500 !important;
      font-family: "Open Sans" !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
    &__menu {
      background-color: transparent !important;
      padding: 0 !important;
      box-shadow: unset !important;
      z-index: 99999 !important;
    }
    &__menu-list {
      padding: 0 !important;
      box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      background-color: #fff;
    }
    &__option {
      @include paragraph;
      padding: 6px 20px !important;
      max-height: 40px;
      padding-right: 10px !important;
      color: #333738 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      display: flex !important;
      justify-content: space-between !important;
      height: 40px !important;
      align-items: center !important;
      cursor:  pointer !important;
      input {
        cursor:  pointer !important;
      }
      &:hover {
        background-color: $gray400 !important; 
      }
      &--is-focused {
        background-color: #E3E7ED !important; 
      }
      &--is-selected {
        background-color: #ffff !important; 
        color: $graphite500 !important;
      }
      &:first-child {
        border-top-right-radius: 4px !important;
        border-top-left-radius: 4px !important;
      }
      &:last-child {
        border-bottom-right-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
      }
    }
    &__single-value {
      overflow: unset !important;
      color: $graphite500 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
    &__control {
      background-color: $gray400 !important;
      border: 2px solid $gray400 !important;
      flex-wrap: nowrap !important;
      overflow: auto !important;
      padding-left: 10px !important;
      scrollbar-width: none;
      -ms-overflow-style: none; 
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__multi-value {
    &__color {
      width: 10px;
      height: 10px;
      border-radius: 50px;
    }
  }
  &--medium__multi-value {
    background-color: #fff !important;
    padding: 4px 10px 4px 6px !important;
    border-radius: 50px !important;
    flex-shrink: 0;
    svg {
      fill: $graphite200 !important;
      width: 20px !important;
      height: 20px !important;
    }
    &__label {
      overflow: unset !important;
    }
    &__remove:hover {
      background-color: transparent !important;
      cursor: pointer;
    }
    
    
  }
  &--white {
    .multiple-select--small__control, .multiple-select--medium__control, .multiple-select--large__control, .multiple-select-checker__control {
      border: 2px solid #fff !important;
      background-color: #fff !important;
    }
  }
  &--large__control {
    min-height: 48px !important;
    max-height: 48px !important;
  }
  &--medium__control {
    min-height: 40px !important;
    max-height: 40px !important;
  }
  &--small__control {
    min-height: 32px !important;
    max-height: 32px !important;
  }
  &--large__control--menu-is-open, &--medium__control--menu-is-open, &--small__control--menu-is-open {
    box-shadow: 0 0 0 1px #096EB5 !important;
    border: 1px solid #096EB5 !important;
  }
}

.custom-multi-value {
  display: flex;
  align-items: center;
  div {
    margin-right: 5px;
  }
}