
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.single-section-element {
  &--margin-top {
    margin-top: 35px;;
  }
  .switch__wrapper  {
    margin-top: 30px;
  }
  .static-alert {
    margin-bottom: 25px;
  }
  .input__wrapper {
    margin-bottom: 0;
  }
  .select__wrapper {
    margin-bottom: 0px;
  }
  .static-alert--show {
    margin-top: 25px;
  }
  &--strips {
    .input__wrapper {
      margin-bottom: 25px !important;
      &:nth-child(6) {
        margin-bottom: 0 !important;
      }
    }
  }
  &--installation-blinds {
    .building-material-component, .mounting-accessories {
      .am-separator {
        display: none;
      }
      .am-section-checkbox-list {
        margin-bottom: -20px;
      }
    } 
    .mounting-accessories {
      margin-bottom: 35px;
    }
  }
}
.padding-top-separator {
  padding-top: 30px;
}
.padding-bottom-separator {
  padding-bottom: 30px;
}
.static-alert-top {
  .static-alert {
    margin-top: 15px;
  }
  .static-alert--show {
    margin-top: 35px;
  }
}

.gate-size {
  #gateSizeWidth {
    margin-bottom: 25px;
  }
}
.gateDivision {
  .input__wrapper {
    margin-top: 25px;
  }
}
.methodOfOpeningManualAutomatic {
  .second {
    margin-top: 25px;
  }
}
.gateSize {
  .static-alert{
    margin-top: -20px;
  }
  .static-alert--show {
    margin-top: 0;
  }
}
.doorInTheGate {
  margin-top: 20px;
  display: flex;
  gap: 25px;
  flex-direction: column;
}
.sectional-gate__glazing {
  .textarea__wrapper {
    margin-bottom: 0;
  }
}

.window-replacement {
  .input__wrapper {
    margin-bottom: 30px;
  }
}