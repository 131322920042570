
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.kwcc {
  margin-top: 35px;
  &__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__label {
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
  &__input {
    margin-top: 30px;
    .input__wrapper {
      max-width: 290px;
    }
  }
  .am-separator {
    margin-top: 0;
    &--disabled {
      margin-top: 35px;
    }
  }
  .static-alert--show  {
    margin-bottom: 35px;
  }
}