
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.mobile-menu {
  align-items: center;
  background-color: $graphite500;
  bottom: 0;
  display: flex;
  height: 64px;
  justify-content: space-around;
  width: 100vw;
  position: fixed;
  left: 0;
  z-index: 9999999;
  @include desktop {
    display: none;
  }
}

.mobile-menu-item {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background-color: $graphite400;
    border-radius: 5px;
  }
}