
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        

.full-page {
  background-color: $gray400;
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 99999999;
  height: 100%;
  overflow: auto;
}

.am {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  @include tablet {
    padding: 0 50px;
  }
  @include desktop {
    padding: 0 140px
  }
  &__content {
    max-width: 1221px;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 70px;
    .select__control {
      background-color: $white !important;
    }
  }
  .textarea {
    background-color: $white;
  }
  .input__content input {
    background-color: $white;;
  }
}

.am-form {
  &__first-step {
    max-width: 397px;
    width: 100%;
  }
  &__another-step {
    max-width: 603px;
    width: 100%;
  }
}

.am-steps {
  color: $graphite200;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px; 
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  &__go-back {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
}

.am-heading {
  color: $graphite500;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.18px; 
  margin-bottom: 40px;
}

.am-section-heading {
  color: var(--Graphite-500, #333738);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal; 
  margin-bottom: 30px;
  span {
    color: $graphite300;
    font-size: 16px;
    font-weight: 600;
  }
}

.am-section-sub-heading {
  color: $graphite500;
  font-size: 14px;
  font-weight: 600;
}

.am__buttons {
  display: flex;
  gap: 15px;
  margin-bottom: 50px;
  .btn {
    min-width: calc(50% - 10px);
    @include tablet {
      min-width: 200px;
    }
  }
  &--last-step {
    justify-content: space-between;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
    }
    .btn {
      margin-top: 0 !important;
      min-width: unset;
      max-width: unset;
      width: auto !important;
      padding: 0 20px;
    }
  }
  &--margin-top {
    margin-top: 60px;
  }
}

.am-section-checkbox {
  display: flex;
  flex-direction: column;
  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
  }
  gap: 10px 15px;
  .checkbox-part__wrapper {
    width: 100%;
    @include tablet {
      width: calc(50% - 8px);
    }
  }
}

.am-section-checkbox-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .checkbox-part__wrapper {
    width: calc(50% - 8px);
  }
}

.am-alert.static-alert--show{
  margin-top: 20px;
}
.am-separator {
  height: 1px;
  width: 100%;
  background-color: $gray600;
  margin-top: 35px;
  &--no-margin-top {
    margin-top: 0;
  }
}

.am-margin-top {
  margin-top: 35px;
}