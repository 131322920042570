$white: #fff;


$blue800: #042237;
$blue700: #073453;
$blue600: #075388;
$blue500: #096EB5;
$blue400: #3394D8;
$blue300: #67BCF8;
$blue200: #9DD6FF;
$blue100: #a6daff;


$red800: #790F27;
$red700: #B61A3D;
$red600: #DA264E;
$red500: #F53B64;
$red400: #FF5F82;
$red300: #FF89A3;
$red200: #FFBDCC;

$graphite800: #141719;
$graphite700: #202223;
$graphite600: #292C2D;
$graphite500: #333738;
$graphite400: #4A5051;
$graphite300: #6D7678;
$graphite200: #9BA7AA;

$gray700: #404040;
$gray600: #CECECE;
$gray500: #E5E5E5;
$gray400: #F2F2F2;
$gray300: #FAFAFA;
$gray100: #F2F2F2;

$green: #38D58B;
$orange500: #F29225;
$error: #E20D0D;

$blue500: #096EB5;
$blue800: #042237;