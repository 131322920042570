
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.tilting-gate-selects {
  .select__wrapper {
    &:nth-child(2) {
      margin-top: 25px;
    }
  }
}