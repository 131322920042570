
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.receipt-protocol {
  width: 100%;
  max-width: 809px;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 150px;
  }
  &__top {
    margin-bottom: 65px;
  }
  &__heading {
    color: $graphite500;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.18px;
  }
  &__name {
    color: $blue500;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  &__halfs {
    display: flex;
    gap: 15px;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
    }
  }
  &__half {
    width: 100%;
  }
  &__box {
    padding: 20px 20px 25px 20px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid transparent;
    &__heading {
      color: $graphite500;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid #CECECE;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    &__paragraph {
      color: $graphite500;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
      line-height: 16px;
    }
    &__footer {
      border-top: 1px  dashed #CECECE;
      padding-top: 15px;
      color: $graphite500;
      font-size: 14px;
      font-weight: 400;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .am-separator {
      margin-top:  0;
      margin-bottom: 20px;
    }
    &--error {
      border: 1px solid #E73458;
    }
  }
  &__checklist{
    &__icon {
      margin-right: 15px;
    }
    &__element {
      color: $graphite500;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 20px;
      letter-spacing: -0.16px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 15px;
  }
  &__list-item {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    list-style: disc;
  }
  &__rules {
    color: $graphite500;
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    margin: 50px 0;
  }
  &__buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .btn {
      min-width: calc(50% - 7px);
    }
  }
  &__client {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    margin: 24px 0;
    &--comment {
      margin-bottom: 0 !important;
      .textarea__wrapper{
        margin-bottom: 0 !important;
      }
    }
    &--comment-top {
      display: flex;
      align-items: center;
    }
    label {
      margin-left: 5px;
    }
    input {
      width: 18px;
      height: 18px;
    }
  }
  &__signature {
    &__icon {
      cursor: pointer;
    }
    &__heading {
      height: 35px;
      display: flex;
      justify-content: space-between;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &__signature-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    .static-alert--show {
      margin-top: 24px;
    }
  }
  &--customer-comments {
    margin-bottom: 4px !important;
    flex-direction: row !important;
    span {
      margin-left: 5px;
    }
  }
}