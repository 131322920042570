
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.file-item {
  width: 100%;
  border-radius: 4px;
  padding: 10px 16px;
  margin-top: 15px;
  background-color: $gray100;
  position: relative;
  min-height: 40px;
  &__label {
    color: $graphite400;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    padding-right: 30px;
  }
  &__icon {
    width: 20px;
    height: 20px;
    &__wrapper {
      cursor: pointer;
      display: flex;
      position: absolute;
      right: 16px;
      top: 10px;
      gap: 10px;
    }
  }
}