
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.checklist {
  &__bar-heading {
    margin-bottom: 20px !important;
    margin-top: 14px !important;
  }
  &__heading {
    margin-bottom: 0px !important;
  }
  &__item {
    margin-top: 10px;
    min-height: 52px;
    .checkbox-part-label::after {
      top: 19px !important;
    }
  }
  &__alert {
    margin-top: 20px;
    padding: 9px 72px 9px 24px;
  }
  &__label {
    color: $graphite500;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    cursor: pointer;
  }
  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
  }
  &__content-wrapper {
    border-bottom: 1px solid $gray500;
  }
  &__chevron {
    transition: transform 0.3s ease;
    &.rotated {
      transform: rotate(180deg);
    }
    .svg path {
      stroke: $blue500;
    }
  }
  &__content--expanded {
    max-height: 1000px;
    opacity: 1;
    padding-bottom: 25px;
  }
  &__bar .static-alert {
    margin-top: 20px;
  }
}

.checklist-table__label {
  border-left: 1px solid #CECECE !important;
  border-right: 1px solid #CECECE !important;
}

.checklist-approved {
  margin-bottom: 40px;
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__list-header {
    color: $blue500;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-bottom: 20px;
  }
  &__list-item {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding-left: 39px;
    margin-bottom: 10px;
    position: relative;
    min-height: 24px;
  }
  &__list-item-icon {
    position: absolute;
    left: 0;
    top: 0;
  }
}