
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.interim-date {
  &__action {
    color: $graphite300;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
      path{
        stroke: $graphite300;
      }
    }
  }
  &__form {
    width: 100%;
    margin-top: 20px;
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .input__wrapper {
      .input__icon {
        right: 12px;
        svg path {
          stroke: $blue500
        }
      }
    }
    &__buttons {
      margin-top: -5px;
      display: flex;
      justify-content: space-between;
    }
  }
}