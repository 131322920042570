
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.static-alert {
  display: flex;
  width: 100%;
  padding: 0 72px 0 24px;
  position: relative;
  border-radius: 8px;
  height: 0;
  overflow: hidden;
  transition: 0.2s;
  &--show {
    padding: 17px 72px 17px 24px;
    height: auto;
    transition: 0.2s;
  }
  &__content {
    @include paragraph;
  }
  &__icon {
    position: absolute;
    top: 14px;
    right: 20px;
  }
  &--warning {
    background-color: #F4CCD5;
  }
  &--green {
    background-color: #A5ECCB66;
  }
  &--center {
    display: flex;
    align-items: center;
    justify-items: space-between;
  }
  &--small {
    padding: 12px 72px 12px 24px;
    .static-alert__icon {
      top: 8px;
    }
  }
  &--margin {
    margin-top: 0;
  }
}