
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.calendar-input {
  @include paragraph;

  &__label {
    @include label;
    margin-bottom: 4px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__error {
    color: $error;
    line-height: 16px;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 6px;
  }
  &__icon {
    position: absolute;
    bottom: 11px;
    right: 12px;
    z-index: 99;
    cursor: pointer;
    &--medium {
      bottom: 8px;
    }
    &--with-button {
      bottom: 8px !important;
      flex-direction: row-reverse;
      gap: 8px;
      align-items: center;
      display: flex;
      .btn {
        min-width: unset;
        padding: 8px 19px;
      }
    }
  }
}

.calendar-input {
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.react-datepicker {
  width: 100%;
  border: 0 !important;
  box-shadow: 0px 3px 30px 0px rgba(32, 32, 35, 0.15) !important;
  z-index: 9;
  &__input-container {
    input {
      @include paragraph;
      border: unset;
      background-color: $gray400;
      border: 1px solid $gray400;
      border-radius: 4px;
      padding: 14px 105px 14px 21px;
      width: 100%;
      min-height: 46px;
      max-height: 48px;
    }
  }
  &__day {
    color: $graphite500;
    font-size: 15px;
    font-weight: 500;
    width: 32px !important;
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  &__day-names {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

  }
  &__day-name {
    color: $blue500 !important;
    font-size: 15px;
    font-weight: 400;
    width: 32px !important;
    height: 32px !important;
  }
  &__header {
    background-color: transparent !important;
    border-bottom: 0 !important;
    padding-top: 0 !important;
  }
  &__custom-head {
    margin: 20px 0;
  }
  &__current-month {
    color: $graphite500;
    font-size: 18px;
    font-weight: 700;
    margin: 22px 0 15px 0 !important;
    text-transform: capitalize;
  }
  &__month-container {
    width: 100%;
    padding: 0 40px 0px 40px;
  }
  &__tab-loop {
    position: relative;
    z-index: 999;
  }
  &__navigation-icon--previous {
    &:before {
      color: green;
    }
  }
  &__week {
    display: flex;
    justify-content: space-between;
    div {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &--disabled {
    color: $gray600;
    font-size: 15px;
    font-weight: 600;
  }
  &__day--keyboard-selected {
    background-color: $blue500 !important;
    color: $white !important;
  }
  &__children-container {
    width: 100% !important;
  }
  &__buttons {
    display: flex;
    gap: 15px;
    padding: 35px 40px 20px 40px;
    width: 100%;
    justify-content: space-between;
    .btn {
      min-width: 100px;
      width: 100%;
    }
  }
  &__navigation-icon {
    &:before {
      border-color: $blue500 !important;
    }
  }
  &__triangle {
    display: none;
  }
  &__input-time-container {
    padding: 0 36px 0 40px;
  }
  &__input {
    &--medium {
      max-height: 40px !important;
      min-height: 40px !important;
    }
  }
}

.react-datepicker-time__caption {
  font-weight: 500;
  font-size: 15px;
}
.react-datepicker-time__input input{
  font-size: 15px;
  font-weight: 400;
  padding: 4px 10px;
  background-color: $gray400;
  border: 1px solid $gray400;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker-popper {
  width: 100%;
  .react-datepicker__month-container {
    min-height: 360px;
  }
  .react-datepicker__day--today {
    background-color: $blue100 !important;
    border-radius: 4px;
  }
  .react-datepicker__buttons {
    padding-top: 0 !important;
  }
}

