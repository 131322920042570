
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.edit-employee-rate {
    margin-top: -25px;
    margin-bottom: 30px;
    max-height: 25px;
    &__form {
        margin-bottom: 350px;
    }
}