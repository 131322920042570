
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.sketch {
  &__canvas {
    height: 400px;
    width: 100%;
    background-color: $white;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
  }
  &__buttons {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid $gray600;
    box-shadow: 0px 0px 2px 0px $gray600;
    background-color: $white;
    padding: 5px;
    input {
      border: unset;
      padding: 0 10px;
      background-color: $gray400;
    }
    .btn {
      max-width: 70px;
      min-width: 70px;
      margin-top: 0 !important;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 32px;
    height: 32px;
    border: 0;
    border: 1px solid $gray600;
    border-radius: 4px;
      transition: 0.3s;
      cursor: pointer;
    &--active, &:hover {
      background-color: $blue500;
      transition: 0.3s;
      border: 1px solid $blue500;
      svg {
        path {
          stroke: $white;
        }
      }
    }
    &--right {
      margin-left: auto;
      display: flex;
      gap: 10px;
    }
  }
  &__image-wrapper {
    position: relative;
    .sketch__canvas {
      display: none !important;
    }
  }
  &__image-delete {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid $gray600;
    box-shadow: 0px 0px 2px 0px $gray600;
    justify-content: flex-end;
    display: flex;
    padding: 5px;
  }
}