
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.phone-with-prefix {
  height: 72px;

  &__content {
    display: flex;
    align-items: flex-end;
    width: 100%;
    position: relative;
  }
  .input__error {
    position: absolute;
    bottom: -20px;
  }
  &__separator {
    width: 1px;
    height: 30px;
    position: absolute;
    left: 106px;
    background-color: #D0D5DD;
    bottom: 10px;
    z-index: 3;
  }
  .select {
    padding-bottom: 2px;
    padding-left: 3px;
    &__wrapper {
      z-index: 2;
      width: 100px;
      flex-shrink: 0;
    }
    &__value-container {
      padding: 0 0 0 20px !important;
    }
    &__label {
      margin-bottom: 5px;
    }
    &__control {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      max-height: 38px !important;
      min-height: 38px !important;
      &--is-focused {
        box-shadow: none !important;
      }
    }
  }
  .input {
    padding-left: 120px;
    &__wrapper {
      width: 100%;
      margin-left: -100px;
    }
  }
  .multiple-select--medium__control  {
    margin-bottom: -4px;
    outline: none;
  }
}