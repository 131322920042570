
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.tchdd {
  position: relative;
  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__label {
    color: #333738;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    text-align: left;
    cursor: pointer;

  }
  &__icon {
    width: 20px;
    height: 20px;
      cursor: pointer;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  &__icons {
    margin-right: -15px;
    display: flex;
    div {
      &:first-child {
        margin-right: 4px;
      }
      &:nth-child(2) {
        border-left: 1px solid $gray600;
        width: 24px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &__select {
    position: absolute;
    width: calc(100% + 40px);
    top: 30px;
    left: -20px;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    transition: 0.2s;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    &--expand {
      overflow: auto;
      visibility: visible;
      max-height: 500px;
      transition: 0.2s ;
    }
  }
  &__select-item {
    cursor: pointer;
    padding: 10px 20px;
    background-color: $white;
    &:hover {
      background-color: $gray400;
    }
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }    
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}