
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.Toastify {
  position: relative;
  z-index: 9999999999999;
}
div.Toastify__toast {
    margin-left: auto;
    border-radius: 8px;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 90px;
    @media (max-width: 770px) {
      width: calc(100% - 40px);
      margin-left: 20px;
    }
    @include desktop {
      margin-bottom: unset;
      width: 300px;
    }
  }
  div.Toastify__toast-icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    .svg {
      width: 24px;
      height: 24px;
    }
  }
  div.Toastify__toast-body {
    padding-inline: 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    align-items: flex-start !important;
  }
  button.Toastify__close-button {
    margin-top: 8px;
    margin-right: 6px;
    opacity: 1;
    color: #a6a6a6;
  }
  .toast-title {
    color: $graphite500;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.12px; 
  }
  
  .toast-message {
    color: $graphite400;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.1px; 
  }
  