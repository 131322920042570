
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.failed-meeting-attempt-wrapper {
  display: flex;
  flex-direction: column;
  .static-alert {
    margin-top: 35px;
    background: #F4D7CC;
    font-size: 12px;
    .svg rect {
      fill: #F29225;
    }
  }
}
.failed-meeting-attempt {
  padding: 16px;
  background-color: $gray300;
  border-radius: 4px;
  margin-top: 5px;
  max-width: 460px;
  &--heading {
    color: $graphite500;
    font-size: 12px;
    font-weight: 600;
    line-height: 160%; 
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__line {
    display: flex;
  }
  &__section {
    display: flex;
    flex-direction: column;
  }
  &__label {
    color: $gray700;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
  &__label-gray {
    color: $graphite300;
    font-size: 12px;
    font-weight: 400;
    line-height: 160%;
  }
  &__value {
    color: $gray700;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 3px;
  }
  &__separator {
    width: 100%;
    height: 2px;
    margin: 12px 0;
    background-color: $gray600;
  }
  &__none {
    margin-top: 5px;
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
  }
}