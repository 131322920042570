
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.supplier-table {
  &__actions {
    display: flex;
    gap: 10px;
    margin-right: 20px;
  }
  &__actions-item {
    color: $blue500;
    cursor: pointer;
  }
}