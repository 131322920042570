
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.edit-checklist {
  max-width: 900px;
  width: 100%;
  &__heading {
    color: $graphite500;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.18px;
    width: 100%;
    margin-bottom: 5px;
  }
  &__sub-heading {
    color: $graphite200;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.16px;
  }

  &__form {
    margin-top: 40px;
  }
  &__form-item {
    display: flex;
    gap: 20px;
    flex-direction: column;
    svg path {
      stroke: $graphite200;
    }
    @include tablet {
      flex-direction: row;
    }
    .input__wrapper {
      width: 100%;
      margin-bottom: 0;
      @include tablet {
        margin-bottom: 30px;
      }
      input {
        padding-right: 50px;
        background-color: transparent !important;
        border-radius: 4px;
        border: 1px solid $gray600;
      }
    }
    .switch__label {
      padding: 9px 20px;
    }
    .switch {
      width: 100%;
    }
    .switch__label { 
      width: 100%;
      justify-content: center;
    }
    &--disabled {
      position: relative;
      opacity: 0.5;
      input {
        border: 1px solid $gray600;
      }
      svg {
        position: absolute;
        right: 20px;
        top: calc(50% - 10px);
        transform: translateY(-50%);
        path {
          stroke: #fff;
        }
      }
    }
  }
  &__form-add-item {
    .btn--small {
      min-height: 40px;
      min-width: 140px;
      @include tablet {
        min-width: 200px;
        min-height: 32px;
      }
    }
    @include tablet {
      margin-right: 275px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
    margin-bottom: 50px;
    width: 100%;
    .btn {
      max-width: calc(50% - 10px);
      min-width: calc(50% - 10px);
      @include tablet {
        min-width: 300px;
        max-width: 300px;
      }
      width: 100%;
    }
  }
}