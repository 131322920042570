
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.page {
  min-height: 100%;
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: $gray400;
  display: flex;
}