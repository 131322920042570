
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.installation-arranging {
  &__wrapper {
    margin-top: 10px;
    .modal__information-header {
      margin: 10px 0;
    }
  }
 

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    &--middle {
      margin: 10px 0 25px;
      padding-top: 15px;
      border-top: 1px solid $gray500;
    }
    &--only-one {
      border-top: unset;
    }
  }
  &__item-separator {
    border-top: 1px solid $gray600;
    padding-top: 15px;
  }
  &__label {
    flex-shrink: 0;
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
  }
  &__value {
    text-align: right;
    color: #333738;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    
  }
}