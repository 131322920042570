
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.wrii {
  .single-section-element {
    margin-top: 30px;
  }
  .oldWindowDisposal .am-separator{
    margin-top: 30px;
  }
}