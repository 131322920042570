
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.am-close-modal .modal-description{
  max-width: 500px;
}

.am-top {
  padding-top: 30px;
  width: 100%;
  .btn {
    margin-left: auto;
    min-width: 191px;
    max-width: 191px;
    svg path{
      stroke: $blue500;
      transition: 0.3s;
    }
    &:hover svg path {
      stroke: $white;
      transition: 0.3s;
    }
  }
}
