
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.spout-level {
  margin-top: 35px;
  .am-separator {
    margin-top: 0;
  }
  .static-alert--show {
    margin-bottom: 35px;
  }
}