
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.scheduler-calendar {
 margin-top: 200px;
 margin-left: -20px;
 width: calc(100% + 40px);
 position: relative;
  @include tablet {
    margin-top: 40px;
      margin-left: 0;
      width: 100%;
      margin-bottom: 150px;
  }
  &__selects {
    position: absolute;
    display: flex;
    gap: 20px;
    z-index: 999;
    flex-direction: column;
    top: -190px;
    width: 100vw;
    padding: 0 20px;
    @include tablet {
      padding: unset;
      top: 30px;
      right: 0;
      flex-direction: row;
      width: 100%;
      .multiple-select__wrapper {
        min-width: calc(50% - 10px) !important;
      }
    }
    @include desktop {
      width: auto;
      right: 300px;
      top: -30px;
      .multiple-select__wrapper {
        min-width: 248px !important;
      }
    }
    .select__wrapper, .multiple-select__wrapper {
      min-width: 248px;
      max-width: 100%;
      @include tablet {
        max-width: 248px;
      }
    }
  }
  .loader {
    min-height: 50vh;
    display: flex;
    div {
      margin: auto !important;
    }
  }

  @include tablet {
    .calendar {
      min-height: 920px !important;
      height: auto !important;
      padding-top: 120px;
    }
    .employee-calendar {
      min-height: 960px !important;
      height: auto !important;
    }
    .calendar-toolbar {
      position: absolute;
      top: -20px;
      width: 100%;
    }
  }
  @include desktop {
    .calendar-toolbar {
      position: relative;
      top: 0;
    }
    .calendar {
      padding: 0;
    }
  }
}



.scheduler-calendar .rbc-btn-group:first-child button{
  background-color: $white;
  &:first-child {
    display: none;
  }
}
.scheduler-calendar .rbc-btn-group:last-child button{
  &:last-child {
    display: none;
  }
}
