
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.job-report {
  &__heading {
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-bottom: 15px;
  }
  &__list {
    margin-bottom: 50px;
    @include tablet {
      column-count: 2;
    }
  }
  &__item {
    background-color: #fff;
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
    break-inside: avoid;
    @include tablet {
      max-width: calc(100% + 5px);
    }
    &--safari {
      margin-bottom: 0;
      margin-top: 15px;
    }
    &--summary {
      border: 1px solid $blue500;
    }
  }
  &__item-name {
    border-radius: 6px 6px 0 0;
    padding: 10px 20px;
    background: $gray300;
    color: $graphite400;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    &--blue {
      background-color: #9DD6FF33;
    }
    &--blue-second {
      border-radius: 0;
    }
  }
  &__item-headings {
    color: $graphite300;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    display: flex;
    padding: 10px 30px 15px 30px;
  }
  &__item-heading {
    &:nth-child(5) {
      text-align: right;
    }
  }
  &__item-heading, &__person-label {
    &:nth-child(1) {
      width: 50%;
    }    
    &:nth-child(2) {
      display: none;
      @include desktop {
        display: flex;
        width: 25%;
      }
    }
    &:nth-child(n+3) {
      width: 20%;
      @include tablet {
        width: 12%;
      }
    }
    &:nth-child(5) {
      width: 25%;
      @include tablet {
        width: 30%;
      }
      @include desktop {
        width: 15%;
      }
      flex-shrink: 0;
    }
  }
  &__person {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid $gray500;
    margin: 0 20px;
    &--total {
      margin-top: auto;
      border-bottom: unset;
      .job-report__person--user {
        font-weight: 600;
      }
    }
  }
  &__person-label {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    &:nth-child(5) {
      text-align: right;
    }
    &--total {
      font-weight: 600;
    }
    &--red {
      color: $red500;
    }
    &--strong {
      color: $blue800 !important;
      font-weight: 600 !important;
    }
  }
  &__person--user {
    display: flex;
    align-items: center;
    color: $blue800;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    div {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 10px;
    }
  }
}