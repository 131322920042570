
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.order-preview {
  @include desktop {
    min-width: 460px;
  }
  &__status-desktop {
    display: none;
    @include desktop {
      display: unset;
    }
  }
  &__status-mobile {
    margin-top: 10px;
    .order-status__label {
      display: flex;
    }
    @include desktop {
      display: none;
    }
  }
  &__label {
    margin-top: 10px;
    color: var(--Blue-500, #096EB5);
    font-size: 14px;
    font-weight: 600;
    @include desktop {
      margin-top: 5px;
    }
  }
  &__heading {
    @include desktop {
      span {
        display: none;
      }
    }
  }
  &__separator {
    width: 100%;
    height: 1px;
    background-color: $graphite200;
    margin-top: 20px;
    @include desktop {
      margin-top: 10px;
    }
  }
  &__information-header {
    margin-top: 25px;
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    .btn {
      min-width: 105px;
      max-width: 105px;
    }
  }
  &__information-descript {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
  }
  &__measurer-button {
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    @include tablet {
      // max-width: 375px;
      margin-top: 60px;
    }
    @include desktop {
      margin-top: 60px;
    }
  }

  &__edit-button {
    margin-top: 20px;
    @include desktop {
      max-width: 110px;
      min-width: 110px;
    }
  }
  &__installations {
    margin-top: 25px;
  }
  &__installation {
    margin-top: 15px;
    // padding-left: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  &__installation-label {
    font-size: 12px;
    color: #404040;
    max-width: 203px;
    width: 100%;
  }
  &__installation-value {
    color: #404040;
    font-size: 12px;
    font-weight: 600;
  }
  &__installation-arranged {
    display: flex;
    flex-direction: column;
    align-items: unset;
    .order-preview__installation {
      margin-top: 0;
      padding-left: 0;
      align-items: flex-start;
      max-width: 460pxt;
    }
    .order-preview__installation-value {
      line-height: 20px;
    }
    .am-separator {
      margin-top: 5px;
      &:last-child {
        display: none
      }
    }
  }
  .failed-meeting-attempt-wrapper {
    max-width: 460px;
  }
  &__error-dimension {
    margin-top: 25px;
    min-height: 69px;
    margin-bottom: 20px !important;
  }
  &__set-appointment {
    width: 100%;
    margin-bottom: 35px;
  }
}