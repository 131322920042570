
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.cpm {
  @include tablet {
    min-width: 500px;
  }
  &__form  {
    .input__wrapper {
      &:nth-child(1) {
        margin-bottom: 30px !important;
      }
    }
    .modal-buttons {
      margin-top: 60px;
    }
  }
}