
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.order-table  {
  .rc-table-tbody td:first-child {
    @include tablet {
      text-align: left;
    }
  }
  .active-row {
    z-index: 9999;
    position: relative;
  }
}

.delete-activity {
  min-width: 600px;
}