
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.items-left-at-client {
  &__button {
    min-width: 147px;
  }
  &__heading {
    margin-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    .btn {
      height: 40px;
      transform: 0.3s;
      &:hover {
        transform: 0.3s;
        svg path{
          stroke: #fff;
        }
      }
    }
    svg path{
      stroke: #096EB5;
    }
    p span {
      margin-left: 5px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 25px;
  }
  &__item {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    background-color: $gray100;
    align-items: center;
    &--label {
      color: $gray700;
      font-size: 12px;
      font-weight: 400;
      line-height: 138%;
    }
    &--icon {
      width: 20px;
      height: 20px;
    }
  }
  &__item-delete {
    cursor: pointer;
  }
  &--active-order {
    margin-bottom: 25px !important;
  }
}