@mixin heading {
  color: $graphite500;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; 
  letter-spacing: -0.2px;
}


@mixin paragraph {
  color: $graphite500;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
  letter-spacing: -0.14px; 
}


@mixin label {
  color: $graphite500;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

