
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.assembly-material {
  &__name {
    padding-left: 20px;
    text-align: left;
  }
  &__cost {
    text-align: right;
    padding-right: 20px;
  }
  &__menu-supplier {
    min-height: 46px;
    @include tablet {
      min-height: 74px;
    }
  }
}
