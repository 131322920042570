
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.uam {
  margin-bottom: 40px;
  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: -20px;
  }
  &__item {
    display: flex;
    padding: 15px 16px;
    border-radius: 4px;
    background: $gray100;
    color: $gray700;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
  &__item-count {
    margin-left: auto;
    max-width: 70px;
    width: 100%;
  }

}