
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.building-material-component {
  margin-bottom: 35px;
  .am-section-heading {
    margin-top: 35px;
  }
  .textarea__wrapper {
    margin-top: 5px;
  }
}