
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.calendar-toolbar {
  display: flex;
  align-items: center;
  padding-right: 1px;
  flex-direction: column-reverse;
  gap: 25px;
  position: relative;
  @include tablet {
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    gap: unset;
  }
  &__left, &__center {
    display: flex;
  }

  &__left {
    width: 100%;
    background-color: #fff;
    justify-content: center;    
    padding: 20px 0 32px;
    @include tablet {
      padding: unset;
      background-color: unset;
      width: auto;
    }
  }
  &__center {
    background: #FFF; 
    color: $graphite400;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    padding: 2px;
    border-radius: 20px;
    &--arranging-measurement {
      margin-right: 40px;
    }
  }
  &__calendar-view {
    padding: 5px 20px;
    cursor: pointer;
    transition: 0.3s;
    &--active {
      transition: 0.3s;
      background: $graphite200;
      border-radius: 20px;
      color: $white;
    }
  }
  &__right {
    z-index: 99;
    min-width: 250px;
    max-width: 250px;
    .select__control{
      background-color: $white !important;
    }
  }
  &__navigates {
    width: 60px;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    gap: 10px;
  }

  &__navigate-item {
    width: 25px;
    height: 25px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    svg path {
      stroke: $blue500;
      @include tablet {
        stroke: $graphite400;
      }
    }
    &--disabled {
      pointer-events: none;
      svg path{
        stroke: $gray600;
      }
    }
    &--left {
      transform: rotate(180deg);
    }
  }

  &__month-and-year {
    width: 100%;
    justify-content: space-between;
    display: flex;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.18px;
    padding: 0 50px;
    @include tablet {
      padding: unset;
      justify-content: unset;
      display: block;
    }
    &--small {
      text-transform: unset;
      padding: 0 20px;
    }
  }

  &__month {
    color: $graphite500;
  }

  &__year {
    margin-left: 5px;
    color: $graphite200;
  }
  &__day-and-month {
    display: flex;
  }
  &__add-appointment {
    width: 40px;
    height: 40px;
    right: 20px;
    background-color: $blue500;
    cursor: pointer;
    position: absolute;
    top: 22px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg path {
      stroke: #fff;
    }
  }
}

// .calendar {
//   // max-width: 880px;
// }
.calendar .rbc-month-view{
  border: unset !important;
  padding: 0 40px 40px 40px;
  background-color: #ffff;
  @include tablet {
    background-color: unset;
    padding: unset;
  }
  .rbc-month-row {
    .rbc-row-segment {
      height: 50px;
      background-color: transparent !important;
      @include tablet {
        height: unset;
        display: block;
      }
    }
    @include tablet {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      &:last-child {
        border-bottom: 1px solid #ddd;
      }
      .rbc-row-bg {
        padding-right: 1px;
      }
    }
  }
  .rbc-month-header {
    border-radius: 6px;
    margin-bottom: 15px;
    @include tablet {
      margin-bottom: unset;
      border-top: 1px solid #ddd;
    }
    .rbc-header {
      background: #FFF; 
      color: $blue500;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.14px;
      text-transform: capitalize;
      border-bottom: unset;
      border-right: unset;
      @include tablet {
        border-bottom: 1px solid #ddd;
        padding: 5px 20px;
      }
      &:first-child {
        border-top-left-radius: 6px;
        @include tablet {
          border-left: 1px solid #ddd;
        }
      }
      &:last-child {
        border-top-right-radius: 6px;
        @include tablet {
          border-right: 1px solid $gray600;
        }
      }
    }
  }
  .rbc-row-bg {
    .rbc-day-bg:nth-child(n + 6) {
        @include tablet {
          background-color: #F5FBFF;
        }
      &.rbc-off-range-bg {
        @include tablet {
          background-color: #F5FBFF;
        }
      }
    }
    .rbc-off-range-bg {
      background-color: rgba($color: #fff, $alpha: 0.45);
    }
    .rbc-day-bg:first-child {
      border-left: 1px solid transparent;
    }
  }
  .rbc-row-content {
    .rbc-row:first-child {
      display: none;
    }
    .rbc-row:nth-child(2) {
      margin-top: 9px;
    }
  }
  .rbc-day-bg {
    position: relative;
    background-color: #fff !important;
    border: 1px solid transparent;
    @include tablet {
      border-left: 1px solid $gray600;
    }
    
    &--active {
      background: #FFF !important;
      @include tablet {
        border: 1px solid $blue500 !important;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
      }
    }
    &--hover {
      cursor: pointer;
      z-index: 5;
    }
    &__inside {
      height: 100%;
      width: 100%;
      background-color: transparent !important;
      z-index: 5;
      position: relative;
      &--active {
        cursor: auto;
      }
      &--active-main {
        z-index: 0;
      }
      .rbc-day-bg {
        display: none;
      }
    }
    &__number {
      position: absolute;
      width: 23px;
      right: calc(50% - 12px);
      top: calc(50% - 12px);
      height: 23px;
      color: $graphite500;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.12px;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 11;
      border-radius: 4px;
      @include tablet {
        right: 6px;
        border-radius: 10px;
        top: 4px;
        background-color: $gray400;
      }
      &--disabled:after {
        content: '';
        width: 23px;
        height: 23px;
        border-radius: 10px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba($color: #fff, $alpha: 0.45)
      }
      &--today, &--active {
        background-color: $blue500;
        color: #fff !important; 
      }
      // &--active {
      //   background-color: $blue500;
      //   color: #fff;
      // }
    }
    &__old-month, &__before-today {
      position: relative;
      @include tablet {
        background-color: #f8f8f8;
      }
      cursor: auto !important;
      &:after {
        content: '';
        background-color: rgba($color: #fff, $alpha: 0.45);
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__before-today {
      pointer-events: none;
      .rbc-day-bg__number {
        color: $graphite200;
        @include tablet {
          color: $graphite400;
        }
      }
    }
  }
  .rbc-date-cell {
    padding-right: 6px;
    padding-top: 6px;
    &.rbc-now .rbc-button-link, &.rbc-current .rbc-button-link {
      background-color: $blue500;
      color: #fff;
    }
  }
  
  .rbc-event {
    background: transparent;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet {
      display: block;
    }
    .react-tooltip {
      z-index: 999;
      cursor: auto;
    }
    &--no-click {
      pointer-events: none;
    }
  }
  .rbc-event-content {
    &--second-element-arranging {
      @include desktop {
        margin-top: 20px;
      }
    }
  }
  .rbc-row-content .rbc-row:nth-child(2) .rbc-event-content__item {
    strong {
      max-width: 124px;
      @media(max-width: 1300px) {
        max-width: 100px;
      }
      @media(max-width: 1150px) {
        max-width: 70px;
      }
    }
  }
  .rbc-event-content__item {
    color: $blue800;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.12px;
    // background: #A5ECCB;
    border-radius: 4px;
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    visibility: hidden;
    @include tablet {
      visibility: visible;
      width: auto;
      height: auto;
      padding: 2px 6px;
    }

    &--disabled:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #fff, $alpha: 0.45); 
      // background-color: #A5ECCB;
    }
    strong {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
}

.rbc-row-content {
  // display: none;
  @include tablet {
    display: block;
  }
}

.employee-calendar.calendar-day .rbc-time-header-content{
  border-top: 1px solid $gray600;
  z-index: 99;
 }

.employee-calendar.calendar-week, .employee-calendar.calendar-day {
  .rbc-time-header-gutter {
    position: relative;
    &:after {
      position: absolute;
      content: 'całodn.';
      color: $blue600;
      top: 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.12px;
      left: -1px;
    }
  }
  .rbc-row-content {
    .rbc-row:first-child {
      margin-top: 5px;
    }
  }
  .rbc-allday-cell .rbc-row-bg {
    .rbc-day-bg {
      &:nth-child(6), &:nth-child(7) {
        background-color: #F5FBFF !important;
      }
    }
  }
  .rbc-event, .rbc-time-view{
    .rbc-time-header {
      .rbc-label {
        width: 45px !important;
      }
      margin-bottom: 10px;
      .rbc-time-header-content {
        border-bottom: 1px solid $gray600;
        .rbc-day-bg:last-child {
          border-right: 1px solid $gray600;
        }
      }
    }
    .rbc-event-content {
      width: 100%;
    }
   .rbc-time-header-content {
      .rbc-event--main--0--3, .rbc-event--main--0--10  {
        width: 100% !important;
      }
      .rbc-event-content__item strong{
        display: block;
      }
      .rbc-event-allday {
        width: calc(100% - 10px) !important;
        margin-left: 5px !important;
      }
      .rbc-event-starts-today {
        margin-left: 5px;
      }
      .rbc-event-continues-prior {
        width: calc(100% - 5px) !important;
        margin-left: 0 !important;
      }
      .rbc-event-continues-after {
        width: calc(100% - 5px) !important;
      }
      .rbc-event-continues-prior.rbc-event-continues-after {
        width: 100% !important;
      }
    }
  }
}

.employee-calendar.calendar-week {
  .rbc-time-header-gutter { 
    &:after { 
      top: 30px;
    }
  }
} 
.employee-calendar {
  .rbc-allday-cell {
    overflow-y: unset;
    // max-height: 125px;
    background-color: #fff;
    .rbc-day-bg__number {
      background-color: transparent !important;
      display: none !important;
    }
  }
  .rbc-time-view .rbc-row-content {
    display: unset !important;
  }
}

.calendar .rbc-time-view {
  border: unset;
  padding: 0 !important;
  .rbc-event {
    background-color: unset;
    color: $blue800;
    border: unset;
    // padding: 1px 4px;
    padding: 0;
    .rbc-event-content {
      padding: 0;
      border-radius: 4px;
    }
  }
  .rbc-event-content {
    &__item {
      padding: 5px;
      height: 100%;
    }
    strong {
      font-weight: 600;
      line-height: 20px;
    }
    p {
      margin-top: 10px;
    }
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-selected {
    border: unset;
  }
  .rbc-row-content {
    display: none;
  }  
  .rbc-current-time-indicator {
    background-color: transparent;
  }
  .rbc-time-gutter {
    margin-top: -6px;
  }
  .rbc-button-link {
    cursor: pointer;
  }
  .rbc-time-header-cell {
    min-height: 31px;
    background-color: $white;
    .rbc-button-link {
      width: 100%;
    }
    .rbc-header{
      padding-top: 5px;
      border-top: 1px solid $gray600;

      span {
        text-transform: capitalize;
        color: $blue500;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.12px;
        display: flex;
        justify-content: space-between;
        padding: 0 5px 0 10px;
      }
      span span {
        width: 20px;
        height: 20px; 
        display: flex;
        align-items: center;
        justify-content: center;
        color: $graphite500;
        background-color: $gray400;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.12px; 
        padding: unset;
      }
    }

    .rbc-today  {
      background-color: $white;
      span span {
        background-color: $blue500;
        color: $white;
      }
    }

  }
  .rbc-time-content {
    border: unset;
    overflow-y: hidden;
  }
  .rbc-timeslot-group {
    min-height: 45px;
  }
  .rbc-time-column .rbc-timeslot-group {
    border: unset;
    .rbc-label {
      color: $blue600;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.14px;
    }
    .rbc-time-slot {
      border-bottom: 1px solid transparent;
    }
    &--active-top-quarter {
      .rbc-time-slot {
        position: relative;
        border-top: 1px solid #CECECE !important;
        border-right: transparent !important;
        border-left: transparent !important;
        &:after {
          content: '';
          top: 25%;
          width: 100%;
          height: calc(75% + 2px);
          position: absolute;
          border-top: 1px solid $blue500 !important;
          border-left: 1px solid $blue500;
          border-right: 1px solid $blue500;
        }
      }
    }
    &--active-top-half {
      .rbc-time-slot {
        position: relative;
        border-top: 1px solid #CECECE !important;
        border-right: transparent !important;
        border-left: transparent !important;
        &:after {
          content: '';
          top: 50%;
          width: 100%;
          height: calc(51% + 4px);
          position: absolute;
          border-top: 1px solid $blue500 !important;
          border-left: 1px solid $blue500;
          border-right: 1px solid $blue500;
        }
      }
    }

    &--active-top-three-quarters {
      .rbc-time-slot {
        position: relative;
        border-top: 1px solid #CECECE !important;
        border-right: transparent !important;
        border-left: transparent !important;
        &:after {
          content: '';
          top: 75%;
          width: 100%;
          height: calc(26% + 1px);
          position: absolute;
          border-top: 1px solid $blue500 !important;
          border-left: 1px solid $blue500;
          border-right: 1px solid $blue500;
        }
      }
    }
    &--active-top {
      .rbc-time-slot{
        border-top: 1px solid $blue500 !important;
        border-left: 1px solid $blue500;
        border-right: 1px solid $blue500;
      }
    }
    &--active-middle {
      .rbc-time-slot{
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: -4px;
          height: calc(100% + 8px);
          border-left: 1px solid $blue500;
          border-right: 1px solid $blue500;
          width: 100%;
        }
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }
    &--active-bottom {
      .rbc-time-slot{
        border-bottom: 1px solid $blue500 !important;
        border-left: 1px solid $blue500;
        border-right: 1px solid $blue500;
        border-top: 1px solid transparent;
      }
    }
    &--active-bottom-three-quarters {
      .rbc-time-slot{
        position: relative;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;
        &:before {
          content: '';
          position: absolute;
          bottom: 25%;
          width: calc(100% + 2px);
          left: -1px;
          margin-bottom: 1px;
          height: 75%;
          border-bottom: 1px solid $blue500 !important;
          border-left: 1px solid $blue500;
          border-right: 1px solid $blue500;
        }
      }
    }

    &--active-bottom-half {
      .rbc-time-slot{
        position: relative;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-top: 1px solid transparent;

        &:before {
          content: '';
          position: absolute;
          bottom: 40%;
          width: calc(100% + 2px);
          left: -1px;
          margin-bottom: 1px;
          height: 60%;
          border-bottom: 1px solid $blue500 !important;
          border-left: 1px solid $blue500;
          border-right: 1px solid $blue500;
        }
      }
    }

    &--active-bottom-quarter {
      .rbc-time-slot{
        position: relative;
        border-left: 1px solid transparent;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        &:before {
          content: '';
          position: absolute;
          bottom: 75%;
          width: calc(100% + 2px);
          left: -1px;
          margin-bottom: 1px;
          height: 26%;
          border-bottom: 1px solid $blue500 !important;
          border-left: 1px solid $blue500;
          border-right: 1px solid $blue500;
        }
      }
    }
    &--this-same-hour-top-quarter {
      .rbc-time-slot{
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 25%;
          width: calc(100% + 2px);
          left: -1px;
          margin-bottom: 1px;
          height: 25%;
          border: 1px solid $blue500;
        }
      }
    }
    &--this-same-hour-top-half {
      .rbc-time-slot{
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 25%;
          width: calc(100% + 2px);
          left: -1px;
          margin-bottom: 1px;
          height: 50%;
          border: 1px solid $blue500;
        }
      }
    }

    &--active {
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15); 
      .rbc-time-slot{
        border: 1px solid $blue500;
      }
    }
  }
  .rbc-day-slot {
    background-color: $white;
    border-left: 1px solid $gray600;
    border-bottom: 1px solid $gray600;
    &--weekend {
      background-color: #F5FBFF !important;
    }
    &:nth-child(7), &:nth-child(8) {
      background-color: #F5FBFF;
    }
    &:last-child {
      border-right: 1px solid $gray600;
    }
    .rbc-events-container {
      border: unset;
      margin: 0;
      padding: 0 5px;
    }
    .rbc-time-slot {
      position: relative;
      border-top: 1px solid $gray600;
    }
    &--before-today {
      background-color: unset;
      .rbc-timeslot-group {
        pointer-events: none !important;
      }
      .rbc-event {
        opacity: 0.5;
      }
      .rbc-time-slot {
        background-color: rgba($color: $white, $alpha: 0.5);
      }

    }
  }
  .rbc-header:last-child {
    border-right: 1px solid $gray600;
  }
  .rbc-event-content__item--one-line {
    display: flex;
    align-items: center;
    p {
      margin: 0 0 0 10px;
    }
  }
}

.calendar-day {
  // .rbc-event {
  //   padding: 5px 6px;
  // }
  height: 139px !important;
  .rbc-time-view {
    display: none;
    @include tablet {
      display: block;
    }
  }
  .rbc-time-view .rbc-day-slot .rbc-event {
    padding: 1px 15px 0 5px;
  }
  &__hour-line {
    position: absolute;
    width: 100%;
    border-top: 2px solid red;
    z-index: 10;
    &:before {
      content: '';
      width: 9px;
      height: 9px;
      display: flex;
      background-color: #E73458;
      margin-top: -6px;
      margin-left: -6px;
      border-radius: 5px;
    }
  }
}

// .rbc-timeslot-group {
//   z-index: 99;
// }
.calendar .rbc-month-view .rbc-day-bg--hover {
  &:hover {
    @include tablet {
      border: 1px solid $blue400 !important;
    }
  }
}
.calendar {
  &__user-select-form {
    display: flex;
    max-width: 100%;
  @include tablet {
    margin-bottom: -45px;
  }
  .user-select-form {
      z-index: 999;
      max-width: 100%;
      @include tablet {
        max-width: 250px;
        margin-left: auto;
      }
    }
  }
}
.calendar-arranging-measurement {
  .rbc-timeslot-group {
    z-index: 9;
    cursor: pointer;
  }
  .rbc-selected {
    outline: none;
  }
  .rbc-events-container .rbc-event {
    z-index: 10;
    outline: none;
    cursor: auto;
  }
  .rbc-month-row .rbc-row {
    @media(max-width: 768px) {
      height: 5px !important;
    }
  }
}
.rbc-header + .rbc-header {
  border-left: unset;
  @include tablet {
    border-left: 1px solid #ddd;
  }
}
.rbc-month-row + .rbc-month-row {
  border-top: unset;
  @include tablet {
    border-top: 1px solid #ddd;
  }
}




.calendar-week {
  &__mobile {
    height: 60px !important;
    .rbc-time-view {
      display: none;
    }
    .calendar-toolbar__left {
      display: none;
    }
    &--arranging-measurement {
      height: 80px !important;
    }
  }
}



.calendar-event {
  &--measurer, &--measurer.rbc-event {
    .rbc-event-content {
      // background-color: #A5ECCB !important;
      border-radius: 4px;
      margin-bottom: auto;
    }
  }
  &__main {
    max-width: calc(100% - 30px);
  }
}



.calendar-main {
  .rbc-month-row {
    overflow: unset; 
    // max-height: 144px;
  }
  .rbc-event {
    margin-top: 0 !important;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    @include tablet {
      height: 26px;
    }
  }
  .rbc-row-content {
    @include tablet {
      z-index: 10 !important;
      &--active {
        z-index: unset !important;
      }
    }
  }
  .rbc-month-view .rbc-event-content__item p{
    display: none;
  }
  .calendar-toolbar__center  {
    // margin-bottom: 200px;
    @include tablet {
      margin-bottom: unset;
    }
  }
}


.rbc-show-more {
  margin-left: 5px;
  color: $graphite300;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.12px;
  pointer-events: none;
  cursor: auto;
  @include tablet {
    pointer-events: unset;
    cursor: pointer;
  }
}





.rbc-month-row {
  .rbc-event--main {
    padding: 0;
    width: 7px;
    height: 7px;
    color: transparent;
    @include tablet {
      height: auto;
      width: calc(100% - 50px);
    }
    margin-left: 10px;
  }
  .rbc-event--arranging-measurement {
    width: calc(100% - 14px);
    margin-left: 7px;
    padding: 0;
  }
}

.rbc-time-view {
  .rbc-event--main {
    border-bottom: 1px solid #fff !important;
    strong {
      color: $blue800;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.12px;

    }
  }
  .rbc-event-content p{
    color: $blue800;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.12px;
  }
  .rbc-event--main--0--2 {
    width: calc(50% - 3px) !important;
    margin-right: 5px;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 50% !important;
      width: 50% !important;
    }
    strong {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
  .rbc-event--main--0--3 {
    width: 33% !important;
    margin-right: 5px;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: calc(33% + 1px) !important;
    }
    &--2 {
      left: calc(66% + 2px) !important;
      margin-right: 0 !important;
      width: 33% !important;
    }
    strong {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
    .rbc-event-content p{
      display: none;
    } 
  }
  .rbc-event--main--0--4 {
    width: 25% !important;
    margin-right: 5px;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 25% !important;
    }
    &--2 {
      left: 50% !important;
    }
    &--3 {
      left: 75% !important;
      width: 25% !important;
    }
    strong {
      display: none;
    }
  }
  .rbc-event--main--0--5 {
    width: 20% !important;
    margin-right: 5px;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 20% !important;
    }
    &--2 {
      left: 40% !important;
    }
    &--3 {
      left: 60% !important;
    }
    &--4 {
      left: 80% !important;
      margin-right: 0 !important;
      width: 20% !important;
    }
    strong {
      display: none;
    }
  }
  .rbc-event--main--0--6 {
    width: 16% !important;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 16% !important;
    }
    &--2 {
      left: 32% !important;
    }
    &--3 {
      left: 48% !important;
    }
    &--4 {
      left: 64% !important;
    }
    &--5 {
      left: 80% !important;
      width: 16% !important;
    }
    strong {
      display: none;
    }
  }
  .rbc-event--main--0--7 {
    width: 14% !important;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 14% !important;
    }
    &--2 {
      left: 28% !important;
    }
    &--3 {
      left: 42% !important;
    }
    &--4 {
      left: 56% !important;
    }
    &--5 {
      left: 70% !important;
    }
    &--6 {
      left: 84% !important;
    }
    &--7 {
      left: 98% !important;
    }
    strong {
      display: none;
    }
  }
  .rbc-event--main--0--8 {
    width: 12% !important;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 12% !important;
    }
    &--2 {
      left: 24% !important;
    }
    &--3 {
      left: 36% !important;
    }
    &--4 {
      left: 48% !important;
    }
    &--5 {
      left: 60% !important;
    }
    &--6 {
      left: 72% !important;
    }
    &--7 {
      left: 84% !important;
    }
    &--8 {
      left: 96% !important;
      width: 14% !important;
    }
    strong {
      display: none;
    }
  }

  .rbc-event--main--0--9 {
    width: 11% !important;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 11% !important;
    }
    &--2 {
      left: 22% !important;
    }
    &--3 {
      left: 33% !important;
    }
    &--4 {
      left: 44% !important;
    }
    &--5 {
      left: 55% !important;
    }
    &--6 {
      left: 66% !important;
    }
    &--7 {
      left: 77% !important;
    }
    &--8 {
      left: 88% !important;
    }
    strong {
      display: none;
    }
  }
  .rbc-event--main--0--10 {
    width: 10% !important;
    &--0 {
      left: 0 !important;
    }
    &--1 {
      left: 10% !important;
    }
    &--2 {
      left: 20% !important;
    }
    &--3 {
      left: 30% !important;
    }
    &--4 {
      left: 40% !important;
    }
    &--5 {
      left: 50% !important;
    }
    &--6 {
      left: 60% !important;
    }
    &--7 {
      left: 70% !important;
    }
    &--8 {
      left: 80% !important;
    }
    &--9 {
      left: 90% !important;
    }
    strong {
      display: none;
    }
  }
  .rbc-event--main--1--1 {
    width: calc(100% - 10px) !important;
    margin-right: 5px;
    left: 10px !important;
  }
  .rbc-event--main--1--2 {
    width: calc(50% - 10px) !important;
    &--0 {
      left: 10px !important;
      margin-right: 5px;
    }
    &--1 {
      left: calc(50% + 5px) !important
    }
  }
  .rbc-event--main--1--3 {
    width: calc(33% - 3px) !important;
    &--0 {
      left: 10px !important;
    }
    &--1 {
      left: calc(33% + 8px) !important
    }
    &--2 {
      left: calc(66% + 6px) !important
    }
    strong {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
    .rbc-event-content p{
      display: none;
    } 
  }
  .rbc-events-container {
    margin: 0 7px !important;
  }
  .rbc-event--main {
    overflow: visible !important;
  }
  .rbc-event-content {
    overflow: hidden;
  }
  .react-tooltip {
    width: auto !important;
    min-width: 400px !important;
  }
}








// .calendar-main {
    
    
// }

.calendar {
  @media(max-width: 768px) {
    .rbc-month-row  {
      .rbc-event--main {
        margin-left: 0;
      }
    }
    .rbc-row-segment {
      display: flex;
      align-items: flex-end;
    }
    .rbc-row {
      height: 5px;
      margin-top: -5px !important;
      margin-bottom: 0;
    }
    .rbc-month-header {
      height: 20px;
      @include tablet {
        height: 30px;
      }
    }
    .rbc-row-content {
      height: 50px;
      @include tablet {
        height: auto;
      }
    }
  }
  @media(max-width: 760px) {
    .rbc-row-segment {
      padding: 0 25px;
    }
  }
  @media(max-width: 700px) {
    .rbc-row-segment {
      padding: 0 20px;
    }
  }
  @media(max-width: 600px) {
    .rbc-row-segment {
      padding: 0 15px;
    }
  }
  @media(max-width: 550px) {
    .rbc-row-segment {
      padding: 0 10px 0 10px;
    }
  }
  @media(max-width: 500px) {
    .rbc-row-segment {
      padding: 0 7px;
    }
  }
  @media(max-width: 450px) {
    .rbc-row-segment {
      padding: 0 5px;
    }
  }
  @media(max-width: 400px) {
    .rbc-row-segment {
      padding: 0 5px;
    }
  }
}


.rbc-day-slot {
  .rbc-timeslot-group:nth-child(17) {
      display: none;
  }
}
.rbc-time-gutter {
  position: relative;
    &:after {
      content: '22:00';
      position: absolute;
      left: 5px;
      color: #075388;
      font-size: 12px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.14px;   
      bottom: 0px;
      @include tablet {
        bottom: -10px;
        font-size: 14px;
      }
    }
}


.rbc-month-view .rbc-row-bg--active .rbc-day-bg__number{
  z-index: 15;
}
.rbc-event--no-click {
  pointer-events: none !important;
  cursor: auto !important;
}

.rbc-day-bg, .rbc-day-bg__number, .rbc-day-bg__inside, .rbc-row-bg .rbc-day-bg--hover  {
  &:focus-visible, &:focus-within, &:focus, &:hover, &:active {
    outline: none;
    background-color: #fff !important;
  }
}