
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.checklist-table  {
  .rc-table-tbody td:first-child {
    @include tablet {
      text-align: left;
    }
  }
  .active-row {
    z-index: 9999;
    position: relative;
  }
  .rc-table-thead {
    display: none;
  }
  .checklist__empty-list {
    background-color: #E20D0D;
    border-radius: 10px;
    color: $blue800;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    @include tablet {
      width: auto;
      height: auto;
      background-color: #FFBDCC;
      margin: 0;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 15px;
    }
  }
  &__label {
    text-align: left !important;
  }
  &__mobile-row {
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
}

