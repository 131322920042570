
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.input {
  @include paragraph;
  min-height: 46px;
  max-height: 48px;
  border-radius: 4px;
  background-color: $gray400;
  border: 1px solid $gray400;
  padding: 14px 21px;
  width: 100%;
  &__optional {
    color: $graphite300;
    font-size: 14px;
    font-weight: 600;
  }

  &--with-icon {
    padding-right: 45px;
  }
  &__label {
    @include label;
    margin-bottom: 4px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__error {
    color: $error;
    line-height: 16px;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 6px;
  }
  &__content {
    position: relative;
  }
  &__eye, &__icon {
    cursor: pointer;
    position: absolute;
    top: 14px;
    right: 20px;
  }
  &__icon {
    cursor: auto;
    &--pointer {
      cursor: pointer;
    }
  }
  &__placeholder-right {
    position: absolute;
    top: 15px;
    right: 20px;
    color: $graphite300;
    font-size: 16px;
    font-weight: 400;
    &--top {
      position: absolute;
      top: -1px;
      right: -5px;
      font-size: 10px;
    }
  }
}