
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.fourstate__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fourstate__field {
  flex-direction: unset;
  align-items: center;
  justify-content: space-between;
  &--margin-top {
    margin-top: 30px;
  }
}

.fourstate__name {
  margin-bottom: 8px;
  font-size: 14px;
}

.fourstate {
  display: flex;
  background-color: #fff;
  padding: 4px 5px;
  gap: 5px;
  min-width: 300px;
  border-radius: 50px;
}

.fourstate__option {
  margin-right: 4px;
  cursor: pointer;
  border: unset;
  border-radius: 4px;
  // background-color: #fff;
  transition: background-color 0.3s;
  border-radius: 40px;
  padding: 9px 20px;
  z-index: 9999;
  display: flex;
  align-items: center;
  color: #333738;
  color: var(--Graphite-500, #333738);
  font-size: 16px;
  font-weight: 600;
  transition: 0.4s;
  width: 33%;
  justify-content: center;

  &.active {
    background-color: #096EB5;
    color: #fff;
    border-color: #007bff;
  }
}

.fourstate__secondary, .fourstate__option {
  background: #F2F2F2;
  // background-color: #e9ecef;
  // border-color: #ced4da;
  // &.active {
  //   background-color: #6c757d;
  //   color: #fff;
  //   border-color: #6c757d;
  // }
}
