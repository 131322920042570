
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  @include tablet {
    justify-content: flex-end;
  }
  &--disabled {
    cursor: auto !important;
    path {
      stroke: $gray500;
    }
  }

  &__next {
    transform: rotate(180deg);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &__prev {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &__prev, &__next {
    background-color: $white;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: 1px solid $gray600;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg {
      height: 18px;
      width: 18px;
    }
  }
  &__page {
    background-color: $white;
    color: $graphite500;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.38;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    &--current {
      background-color: $blue500;
      color: white;
    }

  }
  &__ellipsis {
    width: 40px;
    height: 40px;
    background-color: $white;
    line-height: 1.38;
    font-size: 12px;
    border: unset;
  }
  &__pages {
    button {
      border-right: 1px solid $gray600;
      border-top: 1px solid $gray600;
      border-bottom: 1px solid $gray600;
      &:last-child {
        border-right: 0;
      }
    }
  }
}
