
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.select {
  &__control {
    background-color: $gray400 !important;
    border: 2px solid $gray400 !important;
    min-height: 48px !important;
    max-height: 48px !important;
  }
  &--white {
    .select__control, .select-checker__control, .multiple-select--large__control, .multiple-select--medium__control  {
      border: 2px solid #fff !important;
      background-color: #fff !important;
    }
  }
  &__clear-indicator {
    display: none !important;
  }
  &--disabled {
    pointer-events: none !important;
    .select__control {
    background-color: $gray300 !important;
    border: 2px solid $gray300 !important;
    }
  }
  &__value-container {
    padding: 0 20px !important;
    &--is-multi {
      flex-wrap: nowrap !important;
    }
  }
  &__optional {
    color: $graphite300;
    font-size: 14px;
    font-weight: 600;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__label {
    @include label;
  }
  &__error {
    color: $error;
    line-height: 16px;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    // margin-top: 6px;
  }
  &__indicator-separator {
    display: none !important;
  }
  &__placeholder {
    color: $graphite500 !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  &__menu {
    background-color: $gray400 !important;
    padding: 0 !important;
    box-shadow: unset !important;
    z-index: 999 !important;
  }
  &__menu-list {
    padding: 0 !important;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15);
  }
  &__option {
    @include paragraph;
    padding: 6px 20px !important;
    // max-height: 40px;
    color: #333738 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    &:hover {
      background-color: #E3E7ED !important; 
    }
    &--is-focused {
      background-color: #E3E7ED !important; 
    }
    &--is-selected {
      background-color: #E3E7ED !important; 
      color: $graphite500 !important;
    }
    &:first-child {
      border-top-right-radius: 4px !important;
      border-top-left-radius: 4px !important;
    }
    &:last-child {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
  &__single-value {
    overflow: unset !important;
    color: $graphite500 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  &--optional {
    .css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer {
      display: block !important;
      cursor: pointer;
    }
  }
}

.select-checker {
  color: $graphite500 !important;
  font-size: 14px;
  font-weight: 400;
  &--large, &--medium, &--small{
    &__single-value {
      color: $graphite500 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
    &__indicator-separator {
      display: none;
    }
    &__value-container {
      padding-left: 20px !important;
    }
    &__menu-list {
      padding: 0 !important;
    }
    &__control {
      border: transparent !important;
    }
    &__option {
      color: #333738 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      padding: 10px 21px !important;
      &:hover {
        background-color: $gray400 !important;
      }
      &--is-selected {
        background-color: transparent !important;
        position: relative;
        &:after {
          position: absolute;
          content: '';
          top: 5px;
          right: 10px;
          background: url(../../../assets/files/check-circle.svg);
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &--large__control {
    min-height: 48px !important;
    max-height: 48px !important;
  }
  &--medium__control {
    min-height: 40px !important;
    max-height: 40px !important;
  }

}


.multiple-select--medium__menu-notice--no-options {
  display: none;
}