
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.mal {
  &__form {
    margin-top: 25px;
    .input__wrapper {
      margin-bottom: 10px;
    }
  }
  &__form-activities {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    .btn {
      margin-left: auto;
    }
  }
  &__form-buttons {
    margin-top: 64px;
  }
}