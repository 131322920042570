
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.extra-work {
  .kwcc__switch {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    @include tablet {
      flex-direction: row;
    }
    .tristate__wrapper, .tristate, .switch__wrapper  {
      @media(max-width: 1024px) {
        width: 100%;
      }
    }
  }
  .kwcc__label {
    margin-right: auto; 
    margin-bottom: 15px;
      @include tablet {
        margin-right: unset;
        margin-bottom: unset;
      }
  }
  .am-section-heading {
    margin-top: 35px;
  }
  .switch {
    width: 100%;
    @include tablet {
      width: unset;
    }
  }
  .slider {
    @include tablet {
      min-width: 300px;
    }
    display: flex;
    justify-content: space-around;
  }
}