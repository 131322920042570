
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.user-select-form {
  max-width: 250px;
  width: 100%;
  margin-right: -20px;
  .select__single-value {
    overflow: unset;
  }
  .select__control {
    background-color: $white !important;
    z-index: 999;
  }
}