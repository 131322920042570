
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.textarea {
  border: unset;
  resize: none;
  background-color: $gray400;
  border-radius: 4px;
  min-height: 145px;
  color: #333738;
  font-weight: 400;
  line-height: 21px;
  padding: 14px 21px;
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__label {
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 4px;
  }
  &__error {
    color: $error;
    line-height: 16px;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 6px;
  }
  &:focus-visible, &:focus-within {
    outline: none;
    border: 1px solid #096EB5 !important;
  }
}