
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.user-history-rate {
  &__user-badge {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  &__modal {
    @include desktop {
      min-width: 477px;
    }
  }
  .modal-heading__close {
    display: none;
    @include desktop {
      display: flex;
    }
  }
  .modal-buttons {
    @include desktop {
      display: none;
    }
  }
}



.user-history-rate-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.user-history-rate-list-item {
  background-color: $gray400;
  color: $graphite500;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 10px;
  &__left {
    font-size: 14px;
    font-weight: 400;
  }
  &__right {
    font-weight: 600;
  }
}