
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.arranging-measurement {
  @include tablet {
    margin-top: 90px;
    .user-select-form {
      margin-right: unset;
    }
    .calendar-toolbar {
      position: absolute;
      top: 80px;
      width: calc(100% - 40px);
      &__right {
        display: none;
      }
    }
    .calendar__user-select-form {
      margin-bottom: 20px;
    }
    .user-select-form {
      margin-top: -30px;
      margin-left: unset;
    }
  }
  @include desktop {
  margin-top: 40px;
  max-width: 880px;
  margin-right: 320px;
  margin-bottom: 100px;
    .calendar__user-select-form {
      margin-bottom: -45px;
    }
    .user-select-form {
      margin-top: unset;
      margin-left: auto;
    }
    .calendar-toolbar {
      width: unset;
      position: relative;
      top: unset;
      &__right {
        display: flex;
      }
    }
  }
  .calendar, .calendar-mobile, .week-bar {
    margin-left: -20px;
    width: calc(100% + 40px) !important;
    @include tablet {
      margin-left: 0;
      width: 100% !important;
    }

  }
  .rbc-month-view {
    .rbc-day-bg {
      cursor: pointer;
    }
    
  }
  .rbc-row {
    height: 33px;
  }
  .rbc-month-row {
    overflow: unset;
    max-height: 144px;
  }
  .rbc-row-content {
    max-height: 144px;
  }
  .rbc-event {
    margin-bottom: 0 !important;
  }
  .rbc-day-bg__before-today, .rbc-event {
    cursor: auto !important;
  }
  &__top {
    margin-bottom: 15px;
    margin-left: -20px;
    width: calc(100% + 40px);
    background-color: #fff;
    display: flex;
    padding: 20px 20px 10px 20px;
    gap: 10px;
  }
  &__top-left {
    cursor: pointer;
  }
  &__top-right {
    .bold {
      color: $graphite500;
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.16px;
    }
    .blue {
      margin-top: 2px;
      color: $blue500;
      font-size: 14px;
      font-weight: 600;
    }
  }
}


.arranging-assembly .rbc-month-view .rbc-day-bg{
  cursor: unset !important;
}