
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.receipt-protocol-generator {
  &__button {
    min-width: 225px;
  }
  &__button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__textarea {
    margin-top: 20px;
    margin-bottom: 40px;
    .textarea__label, .textarea__label span{
      color: $blue500;
    }
  }
  .input__optional {
    font-size: 12px;
  }
}