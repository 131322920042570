
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.week-bar {
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: relative;
  margin-top: 190px;
  &__navigation {
    display: flex;
    align-items: center;
    padding-top: 10px;
    position: absolute;
    top: 0px;
    &--left {
      left: -20px;
    }
    &--right {
      right: -25px;
    }
  }
  &__navigation-button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg path {
      stroke: $blue500;
    }
    &--disabled 
    {
      cursor: auto;
      .svg path{
        stroke: $gray600;
        pointer-events: none;
      }
    }
  }
  &--week, &--day {
    margin-top: 0;
    // margin-top: 190px;
    @include tablet {
      margin-top: 0;
    }
  }
}

.arranging-measurement {
  .week-bar--week, .week-bar--day {
    margin-top: 0;
    @include tablet {
      margin-top: 0;
    }
  }
}
.week-tile-view {
  display: flex;
  gap: 9px;
  margin: 23px 37px 0px 57px;
  background-color: #fff;
  justify-content: space-around;
  width: 100%;
  position: relative;
  &__day {
    display: flex;
    flex-direction: column;
    height: 50px;
    align-items: center;
    gap: 5px;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-left: 2px;
    &--first-day {
      margin-left: 10px !important;
    }
    &--active {
      background-color: $blue500;
      span {
        color: #fff;
      }
    }
    &--disabled {
      cursor: auto;
      span {
        color: $gray600;
      }
    }
  }
  &__day-label {
    color: $blue500;
    font-size: 12px;
    font-weight: 400;
  }
  &__day-number {
    color: $graphite500;
    font-size: 16px;
    font-weight: 600;
  }
}