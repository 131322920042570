
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.checkbox-part {
  &__wrapper {
    display: flex;
    padding: 14px 17px;
    border-radius: 4px;
    border: 1px solid $gray600;
    align-items: center;
    cursor: pointer;
    position: relative;
    &--active {
      border: 1px solid $blue500;
    }
  }
  &__click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__required {
    margin-left: auto;
    color: $blue800;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.12px;
    background-color: $blue200;
    padding: 3px 12px;
    border-radius: 50px;
    height: 24px;
    display: flex;
    align-items: center;
  }
}

.checkbox-part {
  opacity: 0;
  position: absolute;   
}

.checkbox-part-label {
  position: relative;
}
.checkbox-part, .checkbox-part-label {
  vertical-align: middle;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $graphite500;
  font-size: 14px;
  font-weight: 400;
}

.checkbox-part + .checkbox-part-label:before {
  flex-shrink: 0;
  content: '';
  background: transparent;
  border-radius: 2px;
  border: 1px solid $graphite200;
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  padding: 2px;
  margin-right: 15px;
  text-align: center;
}

.checkbox-part:checked + .checkbox-part-label:after {
  flex-shrink: 0;
  content: "";
  position: absolute;
  display: inline-block;
  width: 3px;
  height: 7px;
  border: solid $white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border-radius: 0px;
  margin: 0px 15px 5px 5px;
  transform: translateY(-50%, -50%);
  top: calc(50% - 5px) !important;
  left: 2px;
}

.checkbox-part:checked + .checkbox-part-label:before {
  content: '';
  background: $blue500;
  border-radius: 2px;
  border: 1px solid $blue500;
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  padding: 2px;
  margin-right: 15px;
  text-align: center;
}

.radiobox .checkbox-part + .checkbox-part-label::before {
  content: "";
  background: transparent;
  border-radius: 2px;
  border: 1px solid #9BA7AA;
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  border-radius: 10px;
  height: 12px;
  padding: 2px;
  margin-right: 15px;
  text-align: center;
}

.radiobox .checkbox-part:checked + .checkbox-part-label::before {
  content: "";
  background: #096EB5;
  border-radius: 2px;
  border: 1px solid #096EB5;
  display: inline-block;
  vertical-align: middle;
  border-radius: 10px;
  width: 12px;
  height: 12px;
  padding: 2px;
  margin-right: 15px;
  text-align: center;
}