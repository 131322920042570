
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.switch {
  position: relative;
  display: inline-block;
  width: auto;
  height: 48px;
  &__optional {
    color: $graphite300;
    font-size: 12px;
    font-weight: 600;
    margin-left: 4px;

  }
  &__label {
    line-height: 17px;
    text-align: center;
    padding: 9px 40px;
    z-index: 1;
    display: flex;
    align-items: center;
    color: $graphite500;
    color: var(--Graphite-500, #333738);
    font-size: 16px;
    font-weight: 600;
    transition: .4s;
    min-width: 110px;
    justify-content: center;
  }
  &__label-right {
    color: $white;
    transition: .4s;  
  }
  &__secondary .slider{
    background-color: $gray400;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__name {
    color: $graphite400;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 20px;
  }
}

.switch input {display:none;}

.slider {
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
  width: 100%;
  height: 48px;
}

.slider:before {
  position: absolute;
  content: "";
  height: calc(100% - 8px);
  width: calc(50% - 10px);
  top: 16px;
  right: 0.3rem;
  margin-top: -0.7rem;
  background-color: $blue500;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 20px;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider .switch__label-left {
  color: $white;
  transition: .4s;
}

.switch input:checked + .slider .switch__label-right {
  color: $graphite500;
  transition: .4s;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(calc(-100% - 10px));
}  

.slider.round {
  width: 100%;
  border-radius: 1.8rem;
  display: flex;
}
