
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.dashboard {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $gray400;
  overflow: auto;
  overflow-x: hidden;
}

.dashboard-content {
  max-width: 1221px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  &__wrapper {
    width: 100%;
    padding: 0 20px;
    padding-bottom: 70px;
    @include desktop {
      padding-left: 139px;
      padding-right: 80px;
      padding-bottom: 0;
    }
  }
}

.dashboard-tab-name {
  color: $graphite500;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.18px; 
  margin: 20px 0;
}

.dashboard-tab-name-with-back {
  display: flex;
  align-items: center;
  &__icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  svg {
    margin-right: 10px;
  }
}

.dashboard-tab-name-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--only-mobile .btn{
    @include desktop {
      display: none;
    }
  }
}