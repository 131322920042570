
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.floor-general-information {
  .single-section-element  {
    .am-separator {
      background-color: unset;
    }
  }
  &--active {
    .single-section-element  {
      .am-separator {
        background-color: #CECECE;
      }
    }
  }
}