
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.window-accessories {
  margin-top: 30px;
  .textarea__wrapper {
    margin-top: 15px;
  }
}