
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.password-has-been-changed {
  &__description {
    text-align: center;
    max-width: 325px;
    margin: 0 auto;
    @include tablet {
      max-width: 325px;
    }
    @include desktop {
      max-width: 100%;
    }
  }
}