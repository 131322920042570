
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.edit-user {
  &__form {
    margin-top: 30px;
    @include desktop {
      min-width: 377px;
    }
  }
  &__actions {
    margin-top: 60px;
  }
}