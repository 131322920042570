
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.order-status {
  color: $blue800;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.12px;
  border-radius: 10px;
  display: inline-block;
  min-width: 8px;
  min-height: 8px;
  &__name {
    padding-bottom: 10px;
  }
  &__table {
    display: flex;
    align-items: center;
    gap: 8px;
    // margin-bottom: 8px;
    min-height: 40px;
  }
  &--installation-to-determine {
    background: #FF89A4;
    
    @include desktop {
      background: rgba(255, 189, 204, 0.60);
    }
  }
  &--measurement-to-determine {
    background: #CBF1DF;
    
    @include desktop {
      background: #CBF1DF;
    }
  }
  &--measurement {
    background: #FFBDCC
  }
  &--fitter {
    background: #38D58B;
  }
  &--fixedInstallation {
    background: rgba(255, 189, 204, 0.60);
  }
  &--fixedMeasurements {
    background: #CBF1DF;
  }
  &--verification {
    background: #FFCF99 !important;
    @include desktop {
      background: #FFDBB1 !important;
    } 
  }
  &__label {
    color: $blue800;
    padding: 4px 12px;
    display: none;
    text-align: center;
    @include tablet {
      display: flex;
    }
  }
  &__actions {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    &--edit {
      cursor: pointer;
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 16px;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__action-warning, &__action-warning svg {
      width: 24px;
      height: 24px;
  }
  &__action-btn {
    min-width: 65px;
    @include tablet {
      min-width: 105px;
      max-width: unset;
    }
  }
  &__orders {
    display: flex;
    gap: 10px;
    padding-right: 10px;
    justify-content: flex-end;
    @include tablet {
      justify-content: flex-start;
    }
  }
  &__counters {
    font-size: 12px;
    font-weight: 600;
    color: $blue800;
  }
  &--green {
    background: #CBF1DF;
  }
  &--red {
    background: #FFD7E0;
  }
  &--gray {
    background: #F2F2F2;
  }
  &--sub-menu .context-menu__trigger{
    display: flex;
    align-items: center;
    cursor: pointer;
    .label {
      padding-right: 8px;
    }
  }
  &__icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    svg {
      transform: rotate(180deg);
    }
    &--active {
      svg {
        transform: unset;
      }
    }
  }
  .context-menu__trigger--disable {
    svg {
      transform: unset !important;
    }
  }
  .context-menu__item  {
    width: 200px
  }
  &__modal .modal__content{
    max-width: 603px;
  }
}