
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
@import "./_reset";
@import "./_breakpoints";

#root {
  height: 100%;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Open Sans';
}

html {
  overflow: hidden;
}
body {
  overflow: auto;
}

.select__control--menu-is-open {
  box-shadow: 0 0 0 1px green !important;
}

.select__control--is-focused{ 
  &:hover {
    border: 1px solid transparent;
  }
}
input {
  &:focus, &:focus-visible {
    border: 1px solid #096EB5 !important;
    outline: none !important;
  }
}
.rc-table-cell {
  &--no-wrap {
    white-space: nowrap;
  }
}
.select__control input, .select-checker input, .multiple-select input {
  &:focus, &:focus-visible {
    border: unset !important;
    outline: none !important;
  }
}
.form {
  .switch__wrapper {
    margin-bottom: 10px;
  }
  .calendar-input__wrapper, .rate-input__wrapper, .input__wrapper, .select__wrapper, .color-input__wrapper, .textarea__wrapper, .switch__wrapper--form {
    margin-bottom: 30px;
  }
  .modal-sub-heading {
    margin-bottom: 20px;
  }
  .phone-with-prefix {
    margin-bottom: 30px;
    &--error {
      margin-bottom: 50px;
    }
    &__content  .input__wrapper, &__content  .rate-input__wrapper {
      margin-bottom: unset;
    }
    .select__wrapper {
      margin-bottom: unset;
    }
  }
  #phoneNumber {
    min-height: 49px;
    margin-bottom: -1px;
  }
}

.rc-table table{
  width: 100%;
}

.loader div{
  height: auto !important;
}
.left-aligned-cell {
  text-align: left !important;
  padding-left: 20px !important;
}

.closed-table {
  .rc-table-row, .rc-table-tbody tr {
    &:first-child {
      td {
        border-top: 1px solid $gray600 !important;
        border-top: 1px solid $gray600 !important;
        &:first-child {
          border-top-left-radius: 6px !important;
        }
        &:last-child {
          border-top-right-radius: 6px !important;
        }
      }
    }
  }
}

// .active-row {
//   &:after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     background-color: #fff;
//   }
// }

// .loader {
//   width: 48px;
//   height: 48px;
//   border: 5px solid #FFF;
//   border-bottom-color: #FF3D00;
//   border-radius: 50%;
//   display: inline-block;
//   box-sizing: border-box;
//   animation: rotation 1s linear infinite;
//   }

//   @keyframes rotation {
//   0% {
//       transform: rotate(0deg);
//   }
//   100% {
//       transform: rotate(360deg);
//   }
//   } 
