
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.room-list {
  display: flex;
  flex-direction: column;
  // gap: 20px;
  &__heading {
    margin-top: 35px;
  }
  &__item-element {
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    @include tablet {
      width: auto;
    }
  }
  .checkbox-part__wrapper  {
    width: 100%;
    margin: 10px 0 0 0;
    @include tablet {
      min-width: 190px;
      margin: 20px 0;
    }
  }
  .input__wrapper {
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 12px;
    @include tablet {
      max-width: 290px;
      margin-top: unset
    }
  }
  .input__error {
    display: none;
  }
}