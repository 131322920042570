
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.amfs {
  &__form {
    .btn {
      width: 100%;
      margin-top: 50px;
    }
    &--blinds .mounting-accessories{
      margin-bottom: 20px;
    }
    &--external {
      .am__buttons {
        margin-top: -20px;
      }
    .disabled .am-separator{
      display: none !important
    }
    }
  }
  .loader div svg{
    width: 50px;
    height: 50px;
  }
  &--with-warning-comment {
    .error-dimension {
      margin-top: 35px;
    }
  }
}

.garage-door  {
  .am-margin-top {
    margin-bottom: 10px;
  }
  .error-dimension{
    margin-top: 25px;
    margin-bottom: 10px;
  }
}

