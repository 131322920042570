
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.dtb {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 56px;
  max-width: 1221px;
  margin: 0 auto;
  width: 100%;
  &__wrapper {
    background-color: $white;
    padding: 0 20px;
    width: 100%;
    @include desktop {
      padding-left: 139px;
      padding-right: 80px;
    }
  }
  &__logo {
    max-width: 102px;
  }
  &__logout-wrapper {
    color: $blue500;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;
    display: flex;
    align-items: center;
    @include desktop {
      display: none;
    }
    .svg path{
      stroke: $blue500;
    }
    span {
      margin-left: 10px;
    }
  }
  &__user {
    margin-left: auto;
  }
  &__user-label {
    color: $graphite500;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.14px;  
    .context-menu__trigger  {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      } 
    }
  }
  &__user-submenu {
    .context-menu__item{
      min-width: 165px;
    }

  }
}