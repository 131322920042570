
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.active-order {
  &__top {
    display:flex;
    color: $graphite300;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.12px; 
    margin-bottom: 10px;
  }
  &__top-comment {
    margin-left: 150px;
  }
  &__top-start {
    margin-left: 20%;
  }
  &__content {
    display: flex;
    gap: 15px;
    flex-direction: column;
    margin-bottom: 70px;
    @include desktop {
      flex-direction: row;
    }
  }
  &__heading {
    color: $blue500;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.16px; 
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
    }
    &--active {
      cursor: pointer;
    }
  }
  &__heading-optional {
    margin-bottom: 0;
  }
  &__heading-approved {
    flex-direction: row;
    svg {
      margin-left: 10px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    button {
      width: 100%;
    }
  }
  &__cant-happen {
    margin-top: 50px;
    .static-alert {
      background: $gray300;
      @include tablet {
        height: 45px;
      }
      &__icon {
        top: 9px;
      }
      .svg rect {
        fill: $graphite200;
      } 
    }
    &__form {
      margin-top: 20px;
      textarea {
        background-color: #fff;
        @include tablet {
          background-color: $gray400;
        }
      }
    }
    &__form-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      .btn {
        width: 212px;
      }
    }
  }
  &--photos {
    margin-bottom: 16px;
    span {
      cursor: pointer;
    }
  }
}
.fixed-installation {
  &.static-alert--show {
    margin-bottom: 35px;
  }
}

.active-order {
  &__bar {
    
    @include tablet {
      margin-bottom: 50px;
    }
  }
  &__bar-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 25px;
    svg path{
      stroke: $blue500;
      transition: 0.3s;
    }
    .btn:hover {
      svg path{
        stroke: $white;
        transition: 0.3s;
      }
    }
  }
  &__measurement-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .static-alert {
      margin-top: 20px;
    }
    &--skeleton {
      margin: 2px !important;
    }
    .static-alert {
      margin-top: 0;
      &--show {
        margin-top: 20px;
      }
    }
  }
  &__measurement-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    height: 40px;
    background-color: $white;
    @include tablet {
      background-color: $gray100;
    }
    &__name {
      color: $graphite400;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    &__menu {
      display: flex;
    }
    .context-menu__trigger {
      margin-top: -4px;
    }
    .context-menu__wrapper {
      display: flex;
      align-items: center;
    }
  }
  &__measurement-badge {
    color: $blue800;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.12px;
    padding: 4px 15px;
    border-radius: 100px;
    background-color: #FFDBB1;
    margin-right: 25px;
  }
  &__measurement-button {
    display: none;
    @include tablet {
      display: block;
      margin-left: auto;
      margin-top: auto;
    }
  }
}

.active-order {
  &__box {
    @include tablet {
      padding: 25px 50px 40px 25px;
      background-color: $white;
      border-radius: 6px;
    }
    &--left {
      width: 100%;
      @include desktop {
        max-width: 706px;
        min-width: 640px;
      }
    }
    &--measurement {
      height: 100%;
      .archival-dates {
        margin-bottom: 25px;
      }
    }
    .completed-measurements {
      margin-bottom: 25px;
    }
    
  }
  &--right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include desktop {
      max-width: 500px;
    }
  }
  &__details {
    display: flex;
    flex-direction: column;
  }
  &__details-heading {
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 20px;
  }
  &__details-paragraph {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 18px;
  }
  &__details-button {
    width: 100%;
    margin-top: 15px;
    @include tablet {
      display: none;
    }
  }
}

