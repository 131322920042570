
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.context-menu {
  position: absolute;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  opacity: 0;
  transition: 0.2s;
  z-index: -1;
  &--active {
    transition: 0.2s;
    opacity: 1;
    overflow: auto;
    z-index: 91;
  }
  &--left {
    top: 20px;
    right: 0;
  }
  &__item {
    background-color: $white;
    color: $graphite400;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    text-align: left;
    min-width: 190px;
    padding: 10px 20px;
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &:hover {
      background-color: $gray400;
    }
    &--disabled {
      &:hover {
        background-color: $white !important;
        cursor: auto;
      }
    }
  }
  &__content {
    position: relative;
  }
  &__trigger {
    cursor: pointer;
    &--disable {
      pointer-events: none;
    }
  }
}