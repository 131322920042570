
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.sms-code-input {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    max-width: 290px;
    text-align: center;
    margin: 0 auto;
  }
  &__square {
    width: 100%;
    // aspect-ratio: 1 / 1;
    height: 65px;
    font-size: 20px;
    text-align: center;
    background: $gray400;
    border-radius: 4px;
    border: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 126.9%;
    color: $graphite500;
    &--active {
      border-color: #007bff;
      box-shadow: 0 0 3px #007bff;
    }
    &--filled {
      background-color: #eee;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
