
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.color-input {
  gap: 13px;
  display: flex;
  align-items: center;
  min-height: 48px;
  max-height: 48px;
  background-color: #F2F2F2;
  border-radius: 4px;
  margin-top: 4px;
  &__label {
    @include label;
    margin-bottom: 6px;
  }
  &__button {
    font-size: 14px;
    width: calc(50% - 15px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    height: 46px;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      background-color: #cfcece;
    }
    &--disabled {
      pointer-events: none;
    }
  }
  &__separator {
    height: 30px;
    width: 1px;
    background-color: #D0D5DD;
  }
  &__right {
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;
  }
  &__color {
    border-radius: 4px;
    min-height: 30px;
    background-color: gray;
    width: 100px;
    position: relative;
    cursor: pointer;
  }
  .chrome-picker  {
    width: 170px !important;
    position: absolute !important;
    top: 50px;
    z-index: 9;
  }
}