
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.add-user {
  &__form {
    @include desktop {
      min-width: 377px;
    }
    margin-top: 30px;
    .static-alert--show {
      margin-bottom: 30px;
    }
  }
  &__actions {
    margin-top: 60px;
  }

}