
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.desktop-menu-item {
  border-radius: 5px;
  &:hover {
    background-color: $graphite500;
    transition: 0.3s;
  }
  &--admin-logout {
    margin-top: 80px;
    cursor: pointer;
  }
  &--employee-logout {
    margin-top: 242px;
    cursor: pointer;
  }
}
.desktop-menu {
  display: none;
  height: 100vh;
  width: 70px;
  background-color: $graphite500;
  position: fixed;
  bottom: 0;
  padding: 10px 11px;
  transition: 0.3s;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  left: 0;
  z-index: 999;
  @include desktop {
    display: flex;
  }
  .desktop-menu__logo {
    width: 100%;
    &:hover {
      background-color: unset;
    }
    .svg {
      width: 104px;
    }
  }
  &--open {
    width: 242px;
    align-items: flex-start;
    transition: 0.3s;
    .desktop-menu-item {
      justify-content: flex-start;
      padding-right: 20px;
    }
  }
  &-item {
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    height: 48px;
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
    padding: 12px;
    justify-content: unset;
    &:hover {
      background-color: $graphite400;
    }
    &:last-child {
      margin-right: 0;
    }
    &:active {
      text-decoration: none;
    }
    &.active {
      background-color: $graphite400;
      border-radius: 5px;
    }
    .svg {
      flex-shrink: 0;
    }
    &__name {
      text-decoration: none;
      color: $gray300;
      margin-left: 20px;
      transition: 0.3s;
      font-weight: 600;
      font-size: 14px;
      &--darkest {
        color: $gray300;
      }
    }
    &--soon {
      &::after {
        content: 'WKRÓTCE';
        color: #ffffff;
        font-weight: 800;
        font-size: 6px;
        line-height: 7px;
        position: absolute;
        top: -2px;
        background: #5b5b5b;
        width: 36px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__logo {
    margin-bottom: 44px;
  }
}
