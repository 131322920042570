
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.users-table__color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.users-table {
  margin-top: 0;
  margin-bottom: 50px;
  &--active {
    z-index: 9999;
    .rc-table-thead tr th{
      background-color: #D3D3D3;
    }
    .rc-table-row td{
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
      }
      &:first-child {
        &:after {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      &:last-child {
        &:after {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
    .active-row td{
      &:after {
        width: 0;
      }
    }
  }
  @include tablet {
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
  }
  @include desktop {
    overflow-x: unset;
    overflow-y: unset;
  }
  .rc-table-placeholder {
    display: none;
  }
  .user-status {
    padding-left: 5px;
    @include tablet {
      padding-left: unset;
    }
  }
  &--user{
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }
  &__phone {
    display: flex;
    white-space: nowrap;
  }
  .rc-table-thead th{
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    text-align: left;
    padding: 5px 20px;
    @include tablet {
      background-color: $gray500;
      border-right: 1px solid $gray600;
    }
    &:first-child {
      font-weight: 600;
      border-top-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-color: transparent;
    }
  }
  .rc-table-tbody {
    position: relative;
    tr {
      border-radius: 6px !important;
      @include tablet {
        box-shadow: 0;
      }
    }
    td {
      background-color: $white;
      color: $graphite400;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
      margin-bottom: 1px;
      padding: 15px 0;
      @include tablet {
        padding: 15px 20px;
      }
      &:first-child {
        text-align: center;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:last-child {
        text-align: center;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .rc-table-cell-row-hover {
      @include desktop {
        background-color: $gray300;
      }
    }
    .rc-table-expanded-row-level-1  {
      transition: 0.3s;
    }
  }
  .pagination {
    margin-top: 15px;

  }
  &__no-data {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    &--paragraph {
      text-align: center;
      color: $graphite500;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.16px;
    }
  }
}




.user-table-row {
  &__expand {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    background-color: $white;
    padding-bottom: 15px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid $gray600;
    border-bottom: unset;
    border-top: 0;
    z-index: 9;
    position: relative;
  }
  &__expand-top {
    padding-left: 40px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  &__expand-top-location {
    text-align: left; 
    width: 50%;
  }
  &__expand-top-status {
    width: 50%;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
  &__expand-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
  }
  &__expand-item {
    color: $blue500;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-right: 45px;
    cursor: pointer;
    &:last-child {
      margin-right: unset;
    }
  }
}


.users-table-mobile {
  &__name {
    text-align: left;
  }
  &__email {
    text-align: right;
    padding-right: 20px;
    word-break: break-all;
  }
}

.rc-table-row:last-child {
  td {
    border-bottom: 1px solid $gray600;
  }
}
.rc-table-row, .rc-table-tbody tr {
  &:first-child {
    td {
      @include desktop {
        border-top-left-radius: unset !important;
        border-top-right-radius: unset !important;
        border-top: unset;
      }
    }
  }
  td {
    border: 1px solid $gray600;
    border-bottom: unset;
    &:first-child {
      border-right: unset;
    }
    &:last-child {
      border-left: unset;
    }
    &:nth-child(n+2):nth-last-child(n+2) {
      border-left: unset ;
      border-right: unset ;
    }
  }
  .rc-table-cell, td{
    text-decoration: unset !important;
    &:last-child {
      position: relative;
      padding-right: 15px;
      
    }
  }
}

