
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.user-status {
  display: flex;
  align-items: center;
  &__label {
    color: $graphite400;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
    display: none;
    @include tablet {
      display: flex;
    }
  }
  &__circle {
    display: flex;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    &--active {
      background-color: $green;
    }
    &--blocked {
      background-color: $error;
    }
    &--inactive {
      background-color: $gray700;
    }
  }
  &__circle-wrapper {
    margin:10px;
  }
}