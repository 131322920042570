
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.user-badge {
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 600;

  display: flex;
  // align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding-top: 4px;

  @include tablet {
    color: $blue800;
    text-align: center;
    font-size: 12px;
    letter-spacing: -0.12px;
    display: inline-block;
    width: auto;
    padding: 4px 15px 4px 15px;
    border-radius: 100px;
    line-height: 21px; 
  }

  &__first-letter {
    margin-bottom: -4px;
  }
  &__rest-of-title {
    margin-bottom: -4px;
    display: none;
    @include tablet {
      display: inline
    }
  }
}
