
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.calendar-page {
 margin-top: 40px; 
 margin-left: -20px;
 width: calc(100% + 40px);
 @include tablet {
    margin-left: 0;
    width: 100%;
 }
}



.calendar .rbc-btn-group:first-child button{
  background-color: $white;
  &:first-child {
    display: none;
  }
}
.calendar .rbc-btn-group:last-child button{
  &:last-child {
    display: none;
  }
}
.calendar {

}