
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.employees-time-stats {
  display: flex;
  flex-direction: column;
  @include tablet  {
    margin-top: -50px;
  }
  &__selects {
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: auto;
    @include tablet {
      flex-direction: row;
      margin-bottom: 50px;
    }
    .react-datepicker-popper {
      // @include tablet {
      //   transform: translate(-656px, 10px) !important;
      // }
      @include desktop {
        transform: translate(-556px, 10px) !important;
      }
    }
    .date-range-picker-container {
      z-index: 999;
    }
  }
  &__orders {
    .select__wrapper {
      width: 100%;
      margin-top: -5px;
      z-index: 9;
      @include tablet {
        width: 257px;
      }
    }
    &--single {
      margin-bottom: 20px;
    }
  }
  .date-range-picker__wrapper {
    // @include tablet {
    //   width: 860px;
    // }
    @include desktop {
      width: 800px;
    }
  }
  .date-range-picker__input-content  {
    width: 100%;
    @include desktop {
      width: auto;
    }
  }
  &__jobs {
    z-index: 9;
    margin-top: -4px;
    width: 100%;
    position: relative;
    .skeleton {
      margin-top: 5px !important;
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 99999099;
      width: calc(100% + 2px);
    }
    .multiple-select {
      z-index: 9999999;
    }
    @include tablet {
      width: 257px;
    }
  }
}

.ets {
  &__table {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    @include tablet {
      overflow: auto;
    }
    &--employee-time {
      flex-shrink: 0;
    }
    .ets__table-row:nth-child(2) {
      display: none;
    }
    .ets__table-row:nth-child(3) .ets__table-body {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    }
    &--days {
      .ets__table-body:nth-child(2) {
        position:relative;
        border-left: 1px solid $gray600 !important;
        border-bottom: 1px solid $gray600 !important;
        border-radius: 6px 0 0 6px;
        &:after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 5px;
          width: calc(100% - 5px);
          height: 2px;
          background-color: #fff;
        }
      }
      .ets__table-body--weekend {
        &:after {
          background-color: $gray400 !important;
        }
      }
    }
  }
  &__table-wrapper {
    display: flex;
    overflow: auto;
    // min-height: 500px;
    @include tablet {
      overflow: unset;
    }
  }
  &__table-row {
    display: flex;
    height: 53px;
  }
  &__table-heading {
    display: flex;
    flex-direction: column;
    &--employee {
      display: flex;
      width: 243px;
      padding: 15px 20px;
      flex-shrink: 0;
      border-radius: 6px 0px 0px 0px;
      border: 1px solid $gray600;
      border-right: none;
      background: $gray500;
      color: $graphite500;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
    }
    &--time {
      margin-right: 5px;
      display: flex;
      width: 106px;
      padding: 5px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      align-self: stretch;
      border-radius: 0px 6px 0px 0px;
      border: 1px solid $gray600;
      background: $gray500;
      color: $graphite500;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
    }
    &--day {
      width: 69px;
      flex-shrink: 0;
      padding: 5px 25px;
      background: #fff;
      border: 1px solid $gray600;
      border-bottom: 0;
      &:nth-child(2) {
        border-radius: 6px 0px 0px 0px;
      }
      &:nth-child(n + 1) {
        border-right: unset;
      }
    }
    .day-number {
      color: $blue500;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: -0.14px;
      text-align: center;
    }
    .day-name {
      color: var(--Graphite-300, #6D7678);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
      text-align: center;
    }
    &--weekend {
      background: $gray400;
    }
    &--last-element {
      border-right: 1px solid $gray600 !important;
      border-top-right-radius: 6px;
    }
  }
  &__table-body {
    &--employee {
      width: 243px;
      padding: 15px 20px;
      flex-shrink: 0;
      background-color: #fff;
      border: 1px solid $gray600;
      border-top: unset;
      border-radius: 6px 0px 0px 6px;
      .user {
        color: $graphite400;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.14px;
        padding-left: 20px;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .user-color {
        position: absolute;
        top: 5px;
        left: 0;
      }
      .totaled {
        font-weight: 700;
        padding-left: unset;
      }
    }
    &--time {
      background-color: #fff;
      width: 106px;
      padding: 15px 20px;
      flex-shrink: 0;
      text-align: center;
      color: $graphite500;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.14px;
      margin-right: 5px;
      border-radius: 0 6px 6px 0;
      border: 1px solid $gray600;
      border-top: unset;
      border-left: unset;
    }
    &--day {
      padding: 15px 0;
      background: #fff;
      border-top: unset;
      border-radius: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
      width: 69px;
      flex-shrink: 0;
      text-align: center;
      border-right: 1px solid $gray600;
      border-bottom: 1px solid transparent;
      border-top: 1px solid $gray600;
      &:nth-child(n + 1) {
        border-right: unset;
      }
    }
    &--weekend {
      background: $gray400;
    }
    &--ended {
      border-right: 1px solid $gray600 !important;
      border-bottom: 1px solid $gray600 !important;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        right: 5px;
        width: calc(100% - 5px);
        height: 2px;
        background-color: #fff;
        z-index: 1;
      }
      &:before {
        content: '';
        width: calc(100% - 5px);
        height: 1px;
        position: absolute;
        bottom: -1px;
        right: 5px;
        background-color: $gray600 !important;
        z-index: 1;
      }
    }
    &--last-element {
      border-bottom-left-radius: 6px !important;
      border-bottom: 1px solid $gray600 !important;
      &:before {
        content: '';
        width: calc(100% - 5px);
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 5px;
        background-color: $gray600 !important;
        z-index: 1;
      }
    }
    &--last-elements {
      border-bottom: 1px solid $gray600 !important;
      .day-number {
        color: $graphite300 !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.14px;
        pointer-events: none;
      }
      &--only-one {
        .day-number {
        pointer-events: auto !important;
          &--with-time {
            color: $blue500 !important;
          }
        }
      }
      &:before {
        z-index: 99;
      }
    }
    &--job .user {
       padding-left: 0;
    }
    &--job-entry {
      cursor: pointer;
    }
    .day-number {
      color: $gray700;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.14px;
      &--with-time {
        cursor: pointer;
        color: $blue500;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: -0.14px;
        &--noclick {
          cursor: auto;
        }
      }
    }
    &--today {
      background-color: #E6F5FF;
    }
  }
  &__go-back {
    cursor: pointer;
    display: flex;
    color: $graphite300;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    gap: 8px;
    margin-bottom: 13px;
    width: 80px;
    .svg path{
      stroke: $graphite300;
    }
  }
}
