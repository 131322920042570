
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.date-range-picker {
  display: flex;
  position: relative;
  &__wrapper {
    background-color: white;
    display: flex;
    box-shadow: 0px 3px 30px 0px rgba(32, 32, 35, 0.15);
    flex-direction: column;
    padding: 35px 20px 80px 20px;
    @include desktop {
      padding: 35px 40px 80px 40px;
      flex-direction: row;
    }
  }
  &__popper {
    // width: unset;
    line-height: unset;
    width: calc(100% + 40px);
    @include desktop {
      width: unset;
    }
  }
  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include desktop {
      max-width: 137px;
    }
  }

  &__auto-range {
    display: flex;
    flex-direction: column;
    height: 120px;
    position: absolute;
    bottom: -150px;
    width: 100%;
    justify-items: center;
    align-items: center;
    @include desktop {
      position: relative;
      bottom: unset;
      height: auto; 
      align-items: flex-end;
    }
  }
  &__label {
    cursor:pointer;
    font-size: 14px;
    line-height: 17px;
    color: $blue500;
    margin-bottom: 12px;
    text-align: right;
  }
  &__actions {
    display: flex;
    gap: 15px;
    margin-top: 170px;
    margin-bottom: 20px;
    justify-content: center;
    @include desktop {
      margin-left: -137px;
      margin-bottom: -50px;
    }
    .btn {
      max-width: 137px;
      min-width: 137px;
    }
  }

  &__input-container {
    display: flex;
    align-items: center;
    width: 100%;
    &--open {
      pointer-events: none;
    }
  }
  &__input-content {
    display: flex;
    position: relative;
    padding: 10px 33px 10px 60px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    &--open {
      border: 1px solid #096EB5;
    }
  }
  &__input-icon {
    position: absolute;
    left:20px;
    top: 8px;
    .svg path{
      stroke: $graphite300;
    }
  }
  &__input {
    min-width: 175px;
    font-size: 14px;
    font-weight: 400;
    color: $graphite500;
    line-height: 20px;
  }
  &__chevrone {
    left: unset;
    right: 10px;
  }

  .react-datepicker__header {
    position: unset;
    @include desktop {
      position: relative;
    }
  }
  .react-datepicker__day:empty {
    visibility: hidden;
  }

  .react-datepicker__navigation {
    &--previous {
      left: 0px;
    }
    &--next {
      right: 0px;
    }
  }
  .react-datepicker__month-container {
    padding: 0;
    @include tablet {
      &:last-child{
        .react-datepicker__navigation--next { 
          display: none;
        }
        .date-range-picker__auto-range {
          display: none;
        }
      }
    }
    @include desktop {
    max-width: 300px;
      &:last-child{
        margin-left: 50px;
        .react-datepicker__navigation--previous {
          display: none;
        }
        
        .react-datepicker__navigation--next { 
          display: flex;
        }
        .date-range-picker__auto-range {
          position: absolute;
          display: flex;
          right: -150px;
        }
      }
      &:nth-child(2) {
        .react-datepicker__navigation--next { 
          display: none;
        }
        .date-range-picker__auto-range {
          display: none;
        }
      }
    }
    
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__day--today .react-datepicker__day--selecting-range-rank {
    background-color: unset !important;
    color: #000 !important;
  }

    .react-datepicker__day--outside-month {
    background-color: unset !important;
    color: #000 !important;
  }
  .react-datepicker__day--in-range, .react-datepicker__day--range-end {
    background-color: $blue500 !important;
    color: #fff !important;
  }
}
.react-datepicker__day--outside-month {
  visibility: hidden;
}


.date-range-picker-container {
  @include desktop {
    z-index: 999;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input{
    display: none !important;
  }
  .react-datepicker__tab-loop {
    @media(max-width: 1024px) {
      .react-datepicker-popper {
        position: fixed !important;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: unset !important;
      }
    }
  }
}