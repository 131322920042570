
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.login-sms-code {
  &__description {
    text-align: center;
  }
  &__form {
    max-width: 290px;
    margin: 40px auto 0 auto;
    .static-alert {
      margin-bottom: 40px;
    }
  }
  &__forget {
    @include paragraph;
    text-align: center;
    margin-top: 40px;
    @include desktop {
      padding-right: 10px;
    }
  }
  &__forget-link {
    color: $blue500;
    font-weight: 600;
    cursor: pointer;
    margin-left: 5px;
  }
}