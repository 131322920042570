
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.completed-measurements {
  .file-item {
    margin-top: 8px;
  }
  .react-loading-skeleton {
    height: 40px !important;
  }
}