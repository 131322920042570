
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.determined-and-arranged-installation {
  &__header {
    margin-bottom: 25px;
  }
  &__installation-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .installation-planning {
      padding: 4px 17px !important;
    }
  }
  &__installation-item {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    &__left {
      padding: 10px 0;
      border-bottom: 1px solid $gray600 !important;
      margin-bottom: 15px;
      width: 100%;
    }
    &__right {
      width: 105px;
      .btn {
        min-width: 100%;
        margin-top: 6px;
      }
    }
  }
  .installation-planning__top--more {
    border-top: 1px solid $gray600;
    padding-top: 15px;
  }
  &__date {
    .am-separator {
      margin: 25px 0;
      background-color: $gray500;
    }
  }
  &__date-content {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0 72px 0 15px;
    p {
      color: $graphite500;
      font-size: 14px;
      font-weight: 600;
    }
    span {
      color: #333738;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &__teams {
    border-radius: 4px;
    background: $gray300;
    padding: 12px 16px;
    margin-bottom: 10px;
    &__item {
      display: flex;
      justify-content: space-between;
      &--hours {
        margin-top: 15px;
      }
    }
    &__item-label {
      color: $gray700;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px; 
      width: 50%;
    }
    &__item-hours {
      color: $gray700;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      width: 50%;
      text-align: left;
    }
    &__item-workers {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    &__item-employee {
      color: $gray700;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px
    }
    .additional-appointment {
      color: $gray700;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin-bottom: 8px;
      &__comment {
        color: $graphite500;
        font-size: 14px;
        font-weight: 600;
      }
    }
    &--additional-comment {
      &:not(:last-child) {
        display: none;
      }
    }
  }
  &__comment-separator {
    margin: 15px 0 25px 0!important;
  }
  &__comment {
    color: #333738;
    font-size: 14px;
    font-weight: 400;
    padding: 0 15px;
    margin-bottom: 20px;
    line-height: 17px;
  }
}