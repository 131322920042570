
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.construction-site {
  &__list {
    .checkbox-part__wrapper  {
      width: 100%;
    }
    &--half {
      flex-wrap: wrap;
      flex-direction: unset;
      .radiobox {
        max-width: calc(50% - 10px);
      }
    }
  }
  .static-alert {
    margin: 17px 0;
    &--show {
      margin: 20px 0 35px 0;
    }
  }
  .textarea__wrapper {
    margin-top: -20px;
  }
}