
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.login-by-tel {
  &__description {
    text-align: center;
  }
  &__form {
    margin-top: 40px;
    .static-alert--show {
      margin-bottom: 40px;
    }
  }
  &--list {
    margin-top: 50px;
    &--item {
      margin: 10px 0;
      cursor: pointer;
    }
  }
}