
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.history-assembly-material {
  &__name {
    margin-top: 15px;
    display: flex;
    color: #333738;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.14px;
  }
  &__modal {
    @include desktop {
      min-width: 477px;
    }
  }
  .modal-heading__close {
    display: none;
    @include desktop {
      display: flex;
    }
  }
  .modal-buttons {
    @include desktop {
      display: none;
    }
  }
}



.history-assembly-material-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  margin-top: 40px;
}

.history-assembly-material-list-item {
  background-color: $gray400;
  color: $graphite500;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 10px;
  &__left {
    font-size: 14px;
    font-weight: 400;
  }
  &__right {
    font-weight: 600;
  }
}