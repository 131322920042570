
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.window-sills {
  &__heading {
    display: flex;
    align-items: center;
    .btn {
      margin-left: auto;
      max-width: 300px;
      margin-top: unset;
    }
  }
  &__add-button {
    margin-top: 25px !important;
    max-width: 300px;
    margin-left: auto;
  }
  .am-separator {
    margin: 35px 0 35px 0;
  }
  &__row {
    position: relative;
  }
  &__delete {
    position: absolute;
    top: -4px;
    right: 0;
    font-size: 14px;
    color: $graphite300;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    .svg {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
}