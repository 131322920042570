
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.order-preview {
  @include desktop {
    min-width: 460px;
  }
  &__status-desktop {
    display: none;
    @include desktop {
      display: unset;
    }
  }
  &__status-mobile {
    margin-top: 10px;
    .order-status__label {
      display: flex;
    }
    @include desktop {
      display: none;
    }
  }
  &__label {
    margin-top: 10px;
    color: var(--Blue-500, #096EB5);
    font-size: 14px;
    font-weight: 600;
    @include desktop {
      margin-top: 5px;
    }
  }
  &__heading {
    @include desktop {
      span {
        display: none;
      }
    }
  }
  &__separator {
    width: 100%;
    height: 1px;
    background-color: $graphite200;
    margin-top: 20px;
    @include desktop {
      margin-top: 10px;
    }
  }
  &__information-header {
    margin-top: 25px;
    color: $graphite500;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    .btn {
      min-width: 105px;
      max-width: 105px;
    }
  }
  &__information-descript {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
  }
  &__measurer-button {
    width: 100%;
    max-width: 100%;
    margin-top: 50px;
    @include tablet {
      max-width: 100%;
      margin-top: 60px;
    }
    @include desktop {
      margin-top: 60px;
    }
  }
}

.checklist-preview {
  .modal__content {
    display: flex;
    flex-direction: column;
  }
  &__number {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
    margin-right: 5px;
  }
  &__label {
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
  }
  &__item {
    display: flex;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $gray500;
  }
  &__required {
    padding: 2px 15px;
    border-radius: 10px;
    background: rgba(111, 212, 244, 0.45);
    color: $blue800;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.12px;
    margin-left: auto;
  }
  &__empty {
    margin-top: 30px;
    color: $graphite500;
    font-size: 14px;
    font-weight: 400;
  }
  &__btn {
    margin-top: auto;
  }
}