
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.door-sills {
  .static-alert--show {
    margin-top: 30px;
  }
  &__heading {
    display: flex;
    align-items: center;
    .btn {
      margin-left: auto;
      max-width: 300px;
      margin-top: unset;
    }
  }
  &__add-button {
    margin-top: 25px !important;
    max-width: 300px;
    margin-left: auto;
  }
  &__delete {
    font-size: 14px;
    color: $graphite300;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    .svg {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
  div  {
    .am-separator {
      display: flex;
      margin: 0 0 35px 0;
    }
  }
  .door-sills__delete-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .am-separator {
    display: flex;
    justify-content: flex-end;
    .door-sills__delete {
      margin-top: 40px;
      z-index: 9999;
    }
  }
}
.without-separator {
  .am-separator {
    display: none;
  }
}

#floorSpace, #floorSpaceWithReserve {
  pointer-events: none;
}