
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.order-invoice {
  &__skeleton {
    .react-loading-skeleton {
      width: 100% !important;
      height: 40px !important;
    }
  }
  &__alert {
    &.static-alert--show {
      margin-top: 25px !important;
    }
  }
  &__price-form {
    .input__wrapper {
      margin-top: 25px;
    }
  }
  &__price {
      position: relative;
      input {
        padding-right: 200px;
      }
      .input__placeholder-right {
        right: 135px;
      }
    .btn {
      min-width: 105px;
      position: absolute;
      top: 35px;
      right: 10px;
    }
  }
  &__price-bold {
    font-weight: 600;
  }
}