
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.uap {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  width: 100vw;
  height: 100%;
  padding-bottom: 50px;
  @include tablet {
    width: 500px;
  }
  @include desktop {
    width: 600px;
    padding-top: 100px;
  }
  &__box {
    border-radius: 8px;
    background: $white;
    padding: 50px 20px 20px 20px;
    height: 100%;
    @include tablet {
      height: auto;
      padding: 50px 87px 60px 87px;
    }
    @include desktop {
      padding: 50px 105px 60px 105px;
    }
  }
  &__logo {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    @include tablet {
      margin-bottom: 50px;
    }
  }
  &__heading {
    @include heading;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 40px;
    position: relative;
  }
  &__go-back {
    position: absolute;
    left: 0;
    top: 5px;
    cursor: pointer;
  }
  &__description {
    @include paragraph;
  }
  &__button {
    margin-top: 40px;
    .btn {
      width: 100%;
    }
  }
  &__bold-paragraph {
    @include paragraph;
    font-weight: 600;
    text-align: center;
  }
}