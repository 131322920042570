
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.installation-planning__form {
  .static-alert--show {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  margin-top: 15px;
  .react-datepicker__input-container input{
    padding-right: 40px;
  }
  .amp__hours  {
    margin-top: 20px;
    position: relative;
    &--error {
      margin-bottom: 25px;
    }
    .select__wrapper {
      width: 50%;
    }
    .error {
      position: absolute;
      bottom: -10px;
      &--end {
        left: 140px;
      }
    }
  }
}

.installation-planning {
  &__steps {
    color: $blue500;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    display: flex;
    .svg {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      color: $graphite200;
    }
    &__content {
      display:flex;
      flex-direction: column;
    }
    &__paragraph {
      color: $blue500;
      font-size: 14px;
      font-weight: 400;
      margin-top: 8px;
    }
  }
}